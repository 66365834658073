<template>
	<div class="map-part">
		<title1 title="安全指标" subTitle="Security Index" />
		<div class="contion">
			<div class="box">
				<divBox title="近10日告警次数分布">
					<MyEchart
						:visible="tenDaysEmergencyShow"
						:id="'TenDaysEmergency'"
						:options="tenDaysEmergencyOptions" />
				</divBox>
			</div>
			<div class="box">
				<divBox title="各类型平均变化速度">
					<div class="flex SOC-type">
						<span v-for="(item, i) in batteryTypeList" :key="'velocityType' + i"
							:class="{at: i == batteryTypeAt}" @click="setBatteryTypeAt(i)">
							{{ item.label }}
						</span>
					</div>
					<MyEchart
						class="myEchart"
						:visible="batteryTypeStatisticsShow"
						:id="'BatteryTypeStatistics'"
						:options="batteryTypeStatisticsOptions" />
				</divBox>
			</div>
		</div>
	</div>
</template>




<script>
    import divBox from "../common/divBox.vue";
    import MyEchart from '@/views/components/charts/echarts'
    import { dataInterface } from '@/api/dataInterfaceApi';
    import title1 from '@/views/exposition/components/title1'
    import * as echarts from 'echarts'

export default {
	components: {
		MyEchart,
		divBox,
		title1
	},
	data() {
		return {
			bigtitle: {
				CHN: '安全指标',
				ENG: 'Security indicators',
			},
			divflex: 'none',
			// 近10日告警趋势
			tenDaysEmergencyOptions: {},
			tenDaysEmergencyShow: false,
			// 各类型平均变化速度
			batteryTypeStatisticsOptions: {},
			batteryTypeStatisticsShow: false,
			batteryTypeList: [{
					// label: '各电池类型平均变化速度',
					label: '各电池类型',
					unit: 'km/min',
					value: 'quanguo_shengshi-各电池类型平均变化速度'
				},
				{
					// label: '各车辆型号平均变化速度',
					label: '各车辆型号',
					unit: 'km/min',
					value: 'quanguo_shengshi-各车辆型号平均变化速度'
				},
				{
					// label: '各城市平均变化速度',
					label: '各城市',
					unit: 'km/min',
					value: 'quanguo_shengshi-各城市平均变化速度'
				}
			],
			batteryTypeAt: 0,
		} 
	},
	props: {},
	watch: {},
	mounted() {
        this.getData()
    },
	methods: {
		// 刷新接口需要每个子组件封装一个getData();
		getData(data) {
			// 各城市单车日均换电电量
			this.powerExchangeMileage(data)
			// 各城市单车日均换电次数
			this.powerExchangeNumber(data)
			// 各城市车辆平均换下SOC
			this.cheliangSOC(data)
			// 近10日告警趋势
			this.tenDaysEmergency(data)
			// 各类型平均变化速度
			this.batteryTypeStatistics(data)
		},

		// 各城市单车日均换电电量
		powerExchangeMileage(data = {}) {
			let params = {}
			let query = this.$route.query
			params = {
				// 'enCode': 'quanguo-各城市单车日均换电里程',
				'enCode': 'quanguo-各城市单车日均充换电电量',
				'@VEHICLE_TYPE': query.vehicle == '1' ? '乘用车' : (query.vehicle == '2' ? '商用车' : '全部'),
				'@ADCODE': (data ? data.province || data.city || data.adcode : '') || '000000'
			}
			dataInterface.dataFaceApi(params).then((res) => {
				if (!res || !res.length) return;
				let data = res.sort(function(a, b) {
					// return b.per_vehicle_daily_total_elect - a.per_vehicle_daily_total_elect
					return (parseFloat(b.per_vehicle_daily_exchange_elect || 0) + parseFloat(b
							.per_vehicle_daily_charge_elect || 0)) -
						(parseFloat(a.per_vehicle_daily_exchange_elect || 0) + parseFloat(a
							.per_vehicle_daily_charge_elect || 0))
				})
				let xAxisData = []
				let seriesData1 = []
				let seriesData2 = []
				data.map(item => {
					xAxisData.push(item.address)
					seriesData1.push(item.per_vehicle_daily_exchange_elect || 0)
					seriesData2.push(item.per_vehicle_daily_charge_elect || 0)
				})
				this.powerExchangeMileageOptions = {
					title: {
						text: 'kWh',
						top: '3%',
						left: '2%',
						textStyle: {
							color: '#b3b3c9'
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					// grid: {
					// 	left: '3%',
					// 	right: '4%',
					// 	bottom: '3%',
					// 	containLabel: true
					// },
					xAxis: [{
						type: 'category',
						data: xAxisData
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
							name: '换电电量',
							type: 'bar',
							barWidth: 20,
							stack: 'cheliangComparison',
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#83bff6'
									},
									{
										offset: 1,
										color: '#188df0'
									}
								])
							},
							data: seriesData1
						},
						{
							name: '充电电量',
							type: 'bar',
							barWidth: 20,
							stack: 'cheliangComparison',
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#B8FF7E'
									},
									{
										offset: 1,
										color: '#65E2B6'
									}
								])
							},
							data: seriesData2
						}
					]
				}
				this.powerExchangeMileageShow = true
			})
		},

		// 各城市单车日均换电次数
		powerExchangeNumber(data = {}) {
			let params = {}
			let query = this.$route.query
			params = {
				'enCode': 'quanguo-各城市单车日均充换电次数',
				'@VEHICLE_TYPE': query.vehicle == '1' ? '乘用车' : (query.vehicle == '2' ? '商用车' : '全部'),
				'@ADCODE': (data ? data.province || data.city || data.adcode : '') || '000000',
				'@OPER_TYPE': '充换电'
			}
			dataInterface.dataFaceApi(params).then((res) => {
				if (!res || !res.length) return;
				let data = res.sort(function(a, b) {
					return (parseFloat(b.per_vehicle_daily_exchange_num || 0) + parseFloat(b
							.per_vehicle_daily_charge_num || 0)) -
						(parseFloat(a.per_vehicle_daily_exchange_num || 0) + parseFloat(a
							.per_vehicle_daily_charge_num || 0))
				})
				let xAxisData = []
				let seriesData1 = []
				let seriesData2 = []
				data.map(item => {
					xAxisData.push(item.address)
					seriesData1.push(item.per_vehicle_daily_exchange_num || 0)
					seriesData2.push(item.per_vehicle_daily_charge_num || 0)
					// seriesData.push({
					// 	value: parseFloat(item.per_vehicle_daily_exchange_num, 10),
					// 	itemStyle: {
					// 		borderRadius: 5,
					// 	}
					// })
				})
				this.powerExchangeNumberOptions = {
					title: {
						text: '次',
						top: '3%',
						left: '2%',
						textStyle: {
							color: '#b3b3c9'
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					// grid: {
					// 	left: '3%',
					// 	right: '4%',
					// 	bottom: '3%',
					// 	containLabel: true
					// },
					xAxis: [{
						type: 'category',
						data: xAxisData
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
							name: '换电次数',
							type: 'bar',
							barWidth: 20,
							stack: 'cheliangComparison',
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#83bff6'
									},
									{
										offset: 1,
										color: '#188df0'
									}
								])
							},
							data: seriesData1
						},
						{
							name: '充电次数',
							type: 'bar',
							barWidth: 20,
							stack: 'cheliangComparison',
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#3DCAFF'
									},
									{
										offset: 1,
										color: '#00FFD6'
									}
								])
							},
							data: seriesData2
						}
					]
				}
				this.powerExchangeNumberShow = true
			})
		},

		// 各城市车辆平均换下SOC
		cheliangSOC(data = {}) {
			let params = {}
			let query = this.$route.query
			params = {
				'enCode': 'quanguo-各城市车辆平均换下SOC',
				'@VEHICLE_TYPE': query.vehicle == '1' ? '乘用车' : (query.vehicle == '2' ? '商用车' : '全部'),
				'@ADCODE': (data ? data.province || data.city || data.adcode : '') || '000000'
			}
			dataInterface.dataFaceApi(params).then((res) => {
				if (!res || !res.length) return;
				let data = res.sort(function(a, b) { //return b.down_soc - a.down_soc})
					return (parseFloat(b.exchange_down_soc || 0) + parseFloat(b.charge_start_soc ||
						0)) -
						(parseFloat(a.exchange_down_soc || 0) + parseFloat(a.charge_start_soc || 0))
				})
				let xAxisData = []
				let seriesData1 = []
				let seriesData2 = []
				data.map(item => {
					xAxisData.push(item.address)
					seriesData1.push(item.exchange_down_soc || 0)
					seriesData2.push(item.charge_start_soc || 0)
				})
				this.cheliangSOCOptions = {
					title: {
						text: '%',
						top: '3%',
						left: '2%',
						textStyle: {
							color: '#b3b3c9'
						}
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						}
					},
					xAxis: [{
						type: 'category',
						data: xAxisData
					}],
					yAxis: [{
						type: 'value'
					}],
					series: [{
							name: '换下SOC',
							type: 'bar',
							barWidth: 20,
							stack: 'cheliangComparison',
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#3DCAFF'
									},
									{
										offset: 1,
										color: '#00FFD6'
									}
								])
							},
							data: seriesData1
						},
						{
							name: '开始充电SOC',
							type: 'bar',
							barWidth: 20,
							stack: 'cheliangComparison',
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#83bff6'
									},
									{
										offset: 1,
										color: '#188df0'
									}
								])
							},
							data: seriesData2
						}
					]
				}
				this.cheliangSOCShow = true
			})
		},

		// 近10日告警趋势
		tenDaysEmergency(data = {}) {
			let params = {}
			let query = this.$route.query
			params = {
				'enCode': 'quanguo_shengshi-近10天告警趋势',
				'@VEHICLE_TYPE': query.vehicle == '1' ? '乘用车' : (query.vehicle == '2' ? '商用车' : '全部'),
				'@ADCODE': (data ? data.province || data.city || data.adcode : '') || '000000'
			}
			dataInterface.dataFaceApi(params).then((res) => {
					if (!res) return;
					let data = res
					let xAxis = []
					let yAxis = []
					data.map(item => {
						xAxis.push(item.cur_date)
						yAxis.push(parseInt(item.alarm_cnt, 10))
					})
					this.tenDaysEmergencyOptions = {
						title: {
							text: '次'
						},
						tooltip: {
							trigger: 'axis',
							axisPointer: {
								type: 'cross',
								label: {
									backgroundColor: '#6a7985'
								}
							}
						},
						legend: {
							right: '3%',
						},
						xAxis: [{
							type: 'category',
							boundaryGap: false,
							data: xAxis
						}],
						yAxis: [{
							type: 'value'
						}],
						series: [{
							name: '告警数量',
							type: 'line',
							stack: 'Total',
							smooth: true,
							lineStyle: {
								width: 2,
								// color: '#289CFF'
							},
							itemStyle: {
								width: 2,
								color: '#FFCC4C'
							},
							showSymbol: false,
							areaStyle: {
								opacity: 0.8,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: '#FFCC4C'
									},
									{
										offset: 1,
										color: '#261D06'
									}
								])
							},
							emphasis: {
								focus: 'series'
							},
							data: yAxis
						}]
					}
					this.tenDaysEmergencyShow = true
				})
				.catch((err) => {
					console.log(err)
				});
		},

		// 各类型平均变化速度
		setBatteryTypeAt(i) {
			this.batteryTypeAt = i
			this.batteryTypeStatistics()
		},
		// 各类型平均变化速度
		batteryTypeStatistics() {
			let data = this.dataBase || {}
			let params = {}
			let query = this.$route.query
			params = {
				'enCode': this.batteryTypeList[this.batteryTypeAt].value, //'quanguo_shengshi-各电池类型平均能耗',
				'@VEHICLE_TYPE': query.vehicle == '1' ? '乘用车' : (query.vehicle == '2' ? '商用车' : '全部'),
				'@ADCODE': (data ? data.province || data.city || data.adcode : '') || '000000'
			}
			this.batteryTypeStatisticsShow = false
			dataInterface.dataFaceApi(params).then((res) => {
					if (!res || !res.length) return;
					// 排序
					let data = res.sort(function(a, b) {
						return (new Date(a.soc_month.replace('-', '/')) - new Date(b.soc_month.replace('-',
							'/')))
					})
					// let months = []
					let monthData = {}
					let batterys = []
					data.map(item => {
						if (!monthData[item.soc_month]) {
							monthData[item.soc_month] = []
						}
						monthData[item.soc_month].push(item)
						// 所有电池类型
						if (!batterys.includes(item.battery_type || item.cat_model || item.city)) {
							batterys.push(item.battery_type || item.cat_model || item.city)
						}
					})
					let xAxis = Object.keys(monthData)
					let seriesData = []
					// let colors = ['#1968FF', '#3DDDFF', '#FFFA6D', '#77E8A9']
					batterys.map(battery => {
						let sData = {
							name: battery,
							type: 'line',
							showSymbol: false,
							data: []
						}
						seriesData.push(sData)
					})
					xAxis.map(item => {
						seriesData.map((sData) => {
							let a = monthData[item].find(i => {
								return (i.battery_type || i.cat_model || i.city) == sData
									.name
							}) || {}
							sData.data.push(a.avg_change_speed || 0)
							sData.lineStyle = {
								color: this.getRandomColor()
							}
						})
					})
					this.batteryTypeStatisticsOptions = {
						title: {
							text: this.batteryTypeList[this.batteryTypeAt].unit
						},
						legend: {
							hidden: true
						},
						// tooltip: {
						// 	trigger: 'axis',
						// 	axisPointer: {
						// 		type: 'cross',
						// 		label: {
						// 			backgroundColor: '#6a7985'
						// 		}
						// 	}
						// },
						xAxis: [{
							type: 'category',
							boundaryGap: false,
							data: xAxis
						}],
						yAxis: [{
							type: 'value'
						}],
						series: seriesData
					}
					this.batteryTypeStatisticsShow = true
				})
				.catch((err) => {
					console.log(err)
				});
		},
		getRandomColor() {
			var letters = '0123456789ABCDEF';
			var color = '#';
			for (var i = 0; i < 6; i++) {
				color += letters[Math.floor(Math.random() * 16)];
			}
			console.log(color, '颜色')
			return color;
		}
	}
}
</script>

<style lang="scss" scoped>
// @import './part.scss';
.contion {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	margin-top: 20px;
}

.box {
	margin-top: 10px;
	width: 430px;
	height: 300px;
}
</style>