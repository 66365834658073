<template>
    <div
        class="title-tem flex align-center line-height-50"
        :class="[type == 1 ? 'title1' : 'title2', isBg ? '' : 'isBg']">
        <img v-if="type == 1" class="tips" :src="require('@/assets/images/exposition/title-tips-1.png')" alt="" />
        <img v-else class="tips" :src="require('@/assets/images/exposition/title-tips-2.png')" alt="" />
        <span class="text-1" :style="{'font-size': fontSize + 'px'}">{{ title }}</span>
        <span class="text-2">{{ subTitle }}</span>
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: [String, Number],
            default: 1
        },
        title: {
            type: [String, Number],
            default: ''
        },
        subTitle: {
            type: [String, Number],
            default: ''
        },
        isBg: {
            type: Boolean,
            default: true
        },
        fontSize: {
            type: [String, Number],
            default: '16'
        }
    },
    mounted() {},
    data() {
        return {}
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
	.line-height-50{
		line-height: 50px;
	}
.title-tem {
    max-width: 430px;
    &.title1 {
        height: 50px;
        background: url(~@/assets/images/exposition/title-bg-1.png) no-repeat;
        background-size: 100% 100%;
        .tips {
            margin-top: -2px;
        }
    }
    &.title2 {
        .text-1 {
            font-size: 16px;
        }
    }
    &.isBg {
        background: none;
    }
    .tips {
        width: 18px;
        height: 20px;
        margin: 0 10px 0 15px;
    }
    .text-1 {
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        text-shadow: 0px 0px 9px #03B9BD;
        margin-right: 20px;
    }
    .text-2 {
        font-size: 12px;
        color: #215A8E;
    }
}
</style>