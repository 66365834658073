<template>
	<div style="position: relative; width: 100%; height: 100%;">
		<div style="width:100;height:100%" ref="sctterMap" v-if="!isCity"></div>
		<SuperMap v-else class="superMap" :adcode="adcode"></SuperMap>
	</div>
</template>

<script>
import echarts from "echarts";
import resize from "@/components/mixins/resize"
import SuperMap from "@/components/districtMap.vue";
import { dataInterface } from "@/api/dataInterfaceApi";

export default {
	name: "sctterMap",
	mixins: [resize],
	data() {
		return {
			myCharts: null,
			isCity: false,
			geoJson: {
				features: [],
			},
			parentInfo: [{
				cityName: "全国",
				code: 100000,
			}, ],
			provinceStationInfo: [], //省站点信息
			featureCollection: {}, //super-feature
			isPC: window.location.href.indexOf('pc') > -1 ? true : false
		};
	},
	components: {
		SuperMap,
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			if (this.$route.query && this.$route.query.province) {
				this.selectProvinceStationInfo({
					province: this.$route.query.province,
				});
			} else if (this.$route.query && this.$route.query.city) {
				this.selectCityStationInfo({
						city: this.$route.query.city
					},
					this.$route.query.city,
					"city"
				);
			} else {
				this.selectCountryStationInfo();
			}
		},
		getGeoJson(adcode, type, stationInfo) {
			// type： province||city
			let that = this;
			/* eslint-disable */
			AMapUI.loadUI(["geo/DistrictExplorer"], (DistrictExplorer) => {
				console.log('DistrictExplorer')
				var districtExplorer = new DistrictExplorer();
				districtExplorer.loadAreaNode(adcode, function(error, areaNode) {
					if (error) {
						console.error(error);
						return;
					}
					let Json = areaNode.getSubFeatures();
					if (Json.length > 0) {
						that.geoJson.features = Json;
					} else if (Json.length === 0) {
						that.geoJson.features = that.geoJson.features.filter(
							(item) => item.properties.adcode == adcode
						);
						if (that.geoJson.features.length === 0) return;
					}
					that.getMapData(type, stationInfo);
				});
			});
		},
		//获取数据
		async getMapData(type, stationInfo) {
			console.log(stationInfo,'stationInfostationInfo')
			let _this = this;
			stationInfo = stationInfo.reduce((res, item, index, arr) => {
				if (!res[item.adcode || item.district || item.city]) {
					res[item.adcode || item.district || item.city] = item;
				} else {
					res[item.adcode || item.district || item.city] = {
						...item,
						chongdianzhuang: `${
							res[item.adcode || item.district || item.city].chongdianzhuang
						},${item.chongdianzhuang}`,
						huandianzhan: `${
							res[item.adcode || item.district || item.city].huandianzhan
						},${item.huandianzhan}`,
						status: `${
							res[item.adcode || item.district || item.city].status
						},${item.status}`,
					}
				}
				if (index === arr.length - 1) {
					return Object.values(res)
				}
				return res
			}, [])

			let mapData = this.geoJson.features.map((item) => {
				//可以改造数据
				let data = {
					name: item.properties.name,
					//数据value 需要改
					datas: 0,
					position: [],
					cityCode: item.properties.adcode,
					level: item.properties.level, //省 province ，市city ，区district
				};
				let currentData = [];
				currentData = stationInfo.filter(
					(item) =>
					(item.adcode || item.district || item.city) == data.cityCode
				);
				// if(type=='province'){
				//   currentData = stationInfo.filter(
				//     item => item.city == data.cityCode
				//   );
				// } else if(type=='city'){
				//   currentData = stationInfo.filter(
				//     item => item.district == data.cityCode
				//   );
				// }else {
				//   currentData = stationInfo.filter(
				//     item => item.province == data.cityCode
				//   );
				// }

				if (currentData && currentData.length > 0) {
					data.vehicles = currentData[0].cumulative_number_vehicles_network
					data.datas = currentData[0].cumulative_number_vehicles_network
					// 	parseInt(currentData[0].huandianzhan) +
					// 	parseInt(currentData[0].chongdianzhuang);
					// data.chongdianzhuang = currentData[0].chongdianzhuang;
					// data.huandianzhan = currentData[0].huandianzhan;
					// data.status = currentData[0].status || "";
					data.value = item.properties.center;
				}

				console.log("data", data);
				return data;
			});

			console.log("mapData", mapData);

			this.initEcharts(mapData, type);
		},
		initEcharts(mapData, type) {
			this.myChart = echarts.init(this.$refs.sctterMap);
			echarts.registerMap("Map", this.geoJson); //注册
			var domImg = document.createElement("img");
			domImg.style.height =
				domImg.height =
				domImg.width =
				domImg.style.width =
				"8px";
			domImg.src =
				"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAIAAAAmKNuZAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjAgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkE4MTE0OTgyQTdDQzExRUI4Q0RBRkMwQkFGMTY2NDhEIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkE4MTE0OTgzQTdDQzExRUI4Q0RBRkMwQkFGMTY2NDhEIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QTgxMTQ5ODBBN0NDMTFFQjhDREFGQzBCQUYxNjY0OEQiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QTgxMTQ5ODFBN0NDMTFFQjhDREFGQzBCQUYxNjY0OEQiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4v4trwAAAAVklEQVR42mL0D225cu0hAzWAjpY8C9CsL19/wIV4uDnI5gKNYmKgKhjcxrFAggBZiBIuyDhqRQWQOxoVo1ExGhWjUTEaFYMiKoB1LVq1TXZUAI0CCDAAcAlaxCt7dtQAAAAASUVORK5CYII=";

			var domImgHover = document.createElement("img");
			domImgHover.style.height =
				domImgHover.height =
				domImgHover.width =
				domImgHover.style.width =
				"8px";
			domImgHover.src =
				"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAIAAAAmKNuZAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIxLjAgKFdpbmRvd3MpIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkFDQ0Q2RjYyQTdDRDExRUI4ODUxRDIxRjkzMEExNzg2IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkFDQ0Q2RjYzQTdDRDExRUI4ODUxRDIxRjkzMEExNzg2Ij4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QUNDRDZGNjBBN0NEMTFFQjg4NTFEMjFGOTMwQTE3ODYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QUNDRDZGNjFBN0NEMTFFQjg4NTFEMjFGOTMwQTE3ODYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6FboimAAAASklEQVR42mIUnL9XtHsDAzXA69IARjWtXJYX7+FCfyQEKeEyMVAVDG7jWCB+RhaihAsybjQqRqNiNCpGo2I0KoZZVDBSt9oGCDAAhYNrvRu3DWEAAAAASUVORK5CYII=";

			this.myChart.setOption({
					geo: {
						map: "Map",
						aspectScale: 0.75,
						layoutCenter: (type == 'province' || type == 'city') ? ["50%", "55%"] : ["50%", "65%"],
						layoutSize: "100%",
						silent: true,
						roam: true,
						z: 0,
						zoom: (type == 'province' || type == 'city' || this.isPC) ? 0.9 : 1, //缩放比例
						top: (type == 'province' || type == 'city') ? 100 : 100,
						data: mapData,
						label: {
							normal: {
								show: true,
								color: "#fff", //省份标签字体颜色
								formatter: (p) => {
									return p.datas > 0 ? "" : this.formatter(p.name);
								},
							},
							emphasis: {
								show: false,
								color: "#fff", //高亮之后字体颜色
							},
						},
						itemStyle: {
							normal: {
								areaColor: "#022A44", //
								borderColor: "#3DB2C2", //地图外边距
								borderWidth: 1.3,
								shadowColor: "rgb(58,115,192)",
								shadowOffsetX: 5,
								shadowOffsetY: 3,
								shadowBlur: 65,
							},
							emphasis: {
								areaColor: "#47d8fa", //高亮之后区域颜色
								borderWidth: 1.6,
								shadowBlur: 25,
							},
						},
					},
					//提示框组件(可以设置在多种地方)
					tooltip: {
						show: true, //是否显示提示框组件，包括提示框浮层和 axisPointer。
						trigger: "item", //触发类型。item,axis,none
						enterable: true, //鼠标是否可进入提示框浮层中，默认为false，
						showContent: true, //是否显示提示框浮层
						triggerOn: "mousemove", //提示框触发的条件(mousemove|click|none)
						showDelay: 0, //浮层显示的延迟，单位为 ms，默认没有延迟，也不建议设置。在 triggerOn 为 'mousemove' 时有效。
						textStyle: {
							color: "white",
							fontSize: 12,
						},
						padding: [0, 8],
						position: "top",
						hideDelay: 10, //浮层隐藏的延迟
						formatter: (o) => {
							console.log("ooo111", o);
							let item = o.data;
							// let status = (item.status && item.status.split(",")) || "";
							// let chongdianzhuang =
							// 	(item.chongdianzhuang && item.chongdianzhuang.split(",")) || "";
							// let huandianzhan =
							// 	(item.huandianzhan && item.huandianzhan.split(",")) || "";
							// let statusText = "";
							// let statusBg = "";
							let centerHtml = ""
							// let innerHtml = "";
							// if (status) {
								// for (let i = 0; i < status.length; i++) {
								// 	if (status[i] == "暂停营业") {
								// 		statusBg = "yellow";
								// 		statusText = "停站";
								// 	} else if (status[i] == "营业中") {
								// 		statusBg = "green";
								// 		statusText = "运营";
								// 	} else if (status[i] == "建设中") {
								// 		statusBg = "white";
								// 		statusText = "建设";
								// 	}
								// 	innerHtml += `<div class="m-r-40 m-t-20 font-20 flex align-center">
								// 					<div class="font-15 m-r-15 flex align-center" style="white-space:nowrap;">${statusText}:</div>
								// 					<div class="huandianzhan-icon huandianzhan flex align-center ${statusBg}">${huandianzhan[i]}</div>
								// 					<div class="huandianzhan-icon chongdianzhuang flex align-center ${statusBg}">${chongdianzhuang[i]}</div>
								// 				</div>`;
								// }

							// 	centerHtml = `<div style="font-size:0;">
							// 			<div class="marsMapBgTop flex flex-direction justify-center">
							// 				<div class="m-r-40 font-20">${
							// 					this.formatter(item.name)}:</div>
							// 			</div>
							// 			<div class="marsMapBg flex flex-direction">
							// 			</div>
							// 			<div class="marsMapBgBottom"></div>
							// 		</div> `;
							// } else {
								centerHtml = `  
								<div class="marsMapBgAll flex flex-direction">
									<div class="m-r-35 font-20" style="margin-top:-20px;">${this.formatter(item.name)}</div>               
										<div class="m-r-35 font-20 flex align-center" style="margin-top:20px;">
											<div class="vehicleIcon flex align-center">${item.datas}</div>
											</div>
									</div>`;
							// }

							return centerHtml;
						},
						backgroundColor: "transparent", //提示框浮层的背景颜色。
						// borderColor: "white",  //图形的描边颜色
						// borderWidth: 2,
						alwaysShowContent: true,
						transitionDuration: 1, //提示框浮层的移动动画过渡时间，单位是 s，设置为 0 的时候会紧跟着鼠标移动。
					},
					series: [{
							tooltip: {
								show: false,
							},
							type: "effectScatter",
							coordinateSystem: "geo",
							rippleEffect: {
								scale: 10,
								brushType: "stroke",
							},
							showEffectOn: "render",
							itemStyle: {
								normal: {
									shadowColor: "#0ff",
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowOffsetY: 0,
									color: function(params) {
										return new echarts.graphic.LinearGradient(1, 0, 0, 0, [{
												offset: 0,
												color: "#64fbc5",
											},
											{
												offset: 1,
												color: "#018ace",
											},
										]);
									},
								},
							},
							label: {
								normal: {
									color: "#fff",
								},
							},
							symbol: "circle",
							symbolSize: [10, 5],
							data: mapData,
							zlevel: 1,
						},
						{
							type: "scatter",
							coordinateSystem: "geo",
							itemStyle: {
								color: "#f00",
							},
							symbol: "image://" + require("@/assets/images/index/image.png"),
							symbolSize: [32, 41],
							symbolOffset: [0, -20],
							z: 9999,
							data: mapData,
						},
						{
							type: "scatter",
							coordinateSystem: "geo",
							label: {
								normal: {
									show: true,
									formatter: function(params) {
										var name = params.name;
										var value = params.data.datas;
										switch (name) {
											case "内蒙古自治区":
												name = "内蒙古";
												break;
											case "西藏自治区":
												name = "西藏";
												break;
											case "新疆维吾尔自治区":
												name = "新疆";
												break;
											case "宁夏回族自治区":
												name = "宁夏";
												break;
											case "广西壮族自治区":
												name = "广西";
												break;
											case "香港特别行政区":
												name = "香港";
												break;
											case "澳门特别行政区":
												name = "澳门";
												break;
											default:
												break;
										}
										var text = `{fline|${value}}\n{tline|${name}}`;
										return text;
									},
									color: "#fff",
									rich: {
										fline: {
											padding: [0, 25],
											color: "#fff",
											textShadowColor: "#030615",
											textShadowBlur: "0",
											textShadowOffsetX: 1,
											textShadowOffsetY: 1,
											fontSize: 14,
											fontWeight: 400,
										},
										tline: {
											padding: [0, 27],
											color: "#ABF8FF",
											fontSize: 14,
										},
									},
								},
								emphasis: {
									show: true,
								},
							},
							itemStyle: {
								color: "#00FFF6",
							},
							symbol: "image://" + require("@/assets/images/index/img2.png"),
							symbolSize: [100, 50],
							symbolOffset: [0, -60],
							z: 999,
							data: mapData,
						},
					],
				},
				true
			);
			let that = this;
			this.myChart.off("click")
			this.myChart.on("click", (params) => {
				// if (params.componentType=='series') {
				//   // 点击地图，禁止下钻，需要点击mark
				//   return;
				// }
				// debugger
				if (
					that.parentInfo[that.parentInfo.length - 1].code ==
					params.data.cityCode
				) {
					return;
				}
				let data = params.data;
				that.parentInfo.push({
					cityName: data.name,
					code: data.cityCode,
				});
				// 省市请求接口
				let type = "";
				let updateParams = {};
				let currentData = [];
				if (params.data.level == "province") {
					updateParams = {
						province: data.cityCode,
					};
					type = "province";
					// 110100: '北京市',
					// 120100: '天津市',
					// 500100: '重庆市',
					// 310100: '上海市',
					if ([110000, 120000, 500000, 310000].includes(data.cityCode)) {
						updateParams = {
							city: data.cityCode.toString().substring(0, 2) + "0100",
						};
						data.datas &&
							that.selectCityStationInfo(updateParams, data.cityCode, type);
					} else {
						data.datas && that.selectProvinceStationInfo(updateParams);
					}
				} else if (params.data.level == "city") {
					console.log(params, "东莞");
					if (params.data.cityCode == "441900") {
						//东莞特殊处理
						this.isCity = true;
						this.adcode = "4419";
						return;
					}
					updateParams = {
						city: data.cityCode,
					};
					type = "city";
					data.datas &&
						that.selectCityStationInfo(updateParams, data.cityCode, type)
					//获取市的站点信息
					// indexCenter
					// .selectCityStationInfo(updateParams)
					// .then((res) => {
					//   if(!res) return;
					//   that.getGeoJson(data.cityCode,'city',res);
					// })
					// .catch((err) => {
					//   console.log(err);
					// });

				}
				else if (params.data.level == "district") {
					//如果是区级，显示区的样式
					// debugger
					this.$router.push({
						path: '/newVehicleControl',
						query: {
							city: data.cityCode,
							cityName: data.name,
							value: data.value
						}
					})
					return
					// this.isCity = true;
					// this.adcode = params.data.cityCode;
					// this.$router.push({
					//   path: "/districtMap",
					//   query: {
					//     district:params.data.cityCode
					//   },
					// });
					// return;
				}
				//事件公交总线
				// this.$EventBus.$emit('update', updateParams);

				this.$router.push({
					path: ('newVehiclesProvince') > -window.location.href.indexOf > -1 ?
						'/newVehiclesProvince' : '/vehicles',
					query: updateParams
				})

				// if(window.location.href.indexOf('nationwideSite')>-1){
				//   //TODO:暂时方案，刷新页面
				//   this.$router.go(0);
				// }
			});
		},

		//选择切换市县---暂时废弃
		chooseArea(val, index) {
			if (this.parentInfo.length === index + 1) {
				return;
			}
			this.parentInfo.splice(index + 1);
			this.getGeoJson(this.parentInfo[this.parentInfo.length - 1].code);
		},
		//格式化数据
		formatter(name) {
			switch (name) {
				case "内蒙古自治区":
					name = "内蒙古";
					break;
				case "西藏自治区":
					name = "西藏";
					break;
				case "新疆维吾尔自治区":
					name = "新疆";
					break;
				case "宁夏回族自治区":
					name = "宁夏";
					break;
				case "广西壮族自治区":
					name = "广西";
					break;
				case "香港特别行政区":
					name = "香港";
					break;
				case "澳门特别行政区":
					name = "澳门";
					break;
				default:
					break;
			}
			return name;
		},
		//接口请求begin
		//获取全国站点数据
		selectCountryStationInfo() {
			let _this = this;
			// indexCenter
			// .selectCountryStationInfo()
			// .then((res) => {
			//   if(!res) return;
			//   this.provinceStationInfo = res;
			//   this.getGeoJson(100000,'',res);
			// })
			// .catch((err) => {
			// });
			let params = {
				'enCode': "quanguo-各个省市区入网车辆数量", //TiDB-全国地图
				'@ADCODE': '000000'
			};
			dataInterface
				.dataFaceApi(params)
				.then((res) => {
					if (!res) return;
					console.log("全国地图11111111", res);
					this.provinceStationInfo = res;
					this.getGeoJson(100000, "", res);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		//获取省级站点数据
		selectProvinceStationInfo(updateParams) {
			let _this = this;
			//获取省的站点信息
			let params = {
				'enCode': "quanguo-各个省市区入网车辆数量", //TiDB-全国地图
				'@ADCODE': updateParams.province,
			};
			dataInterface
				.dataFaceApi(params)
				.then((res) => {
					if (!res) return
					this.getGeoJson(updateParams.province, "province", res);
				})
				.catch((err) => {
					console.log(err);
				});
		},
		//获取区级站点数据
		selectCityStationInfo(updateParams, adcode, type) {
			let _this = this;
			//获取省的站点信息
			let params = {
				'enCode': "quanguo-各个省市区入网车辆数量", //TiDB-全国地图
				'@ADCODE': adcode,
			};
			dataInterface
				.dataFaceApi(params)
				.then((res) => {
					if (!res) return;
					this.getGeoJson(adcode, type, res)
				})
				.catch((err) => {
					console.log(err);
				});
		},

		//接口请求end
	},
};
</script>
<style>
.flex{
    display: flex;
}

.flex-direction{
    flex-direction: column;
}

.align-center{
    align-content: center;
}

.mapcontainer {
	position: relative;
	width: 100%;
	height: 980px;
	margin: 0 auto;
}

/* .marsMapBgTop {
	width: 214px;
	height: 60px;
	background: url("~@/assets/images/index/mapBgTop.png") repeat;
	background-size: 100% 100%;
	padding-top: 30px;
}

.marsMapBgBottom {
	width: 214px;
	height: 95px;
	background: url("~@/assets/images/index/mapBgBottom.png") repeat;
	background-size: 100% 100%;
}

.marsMapBgAll {
	width: 228px;
	height: 138px;
	background: url("~@/assets/images/index/mapBg.png") repeat;
	background-size: 100% 100%;
	position: relative;
	display: flex;
	padding-top: 30px;
}

.marsMapBg {
	width: 214px;
	background: url("~@/assets/images/index/mapBgCenter.png") repeat;
	background-size: 100% 100%;
	position: relative;
	display: flex;
	justify-content: space-between;
} */

.marsMapBgRed {
	background: url("~@/assets/images/index/mapBg_red.png");
	background-size: 100% 100%;
}

.marsMapBgBlue {
	background: url("~@/assets/images/index/mapBg_blue.png");
	background-size: 100% 100%;
}

.marsMapBgGrey {
	background: url("~@/assets/images/index/mapBg_grey.png");
	background-size: 100% 100%;
}

.m-r-40 {
	margin-left: 25px;
}

.m-r-35 {
	margin-left: 35px;
}

.m-r-15 {
	margin-right: 15px;
}

.m-t-20 {
	margin-top: 20px;
}

.font-15 {
	font-size: 15px;
}

.font-20 {
	font-size: 18px;
}

.m-b-20{
    margin-bottom: 20px;
}

.huandianzhan-icon, .vehicleIcon {
	position: relative;
	width: 50%;
	padding-left: 30px;
	font-weight: 800;
}

.huandianzhan::before {
	content: "";
	background: url("~@/assets/images/index/huandianzhan.png");
	background-size: 100% 100%;
	display: block;
	position: absolute;
	width: 25px;
	height: 15px;
	margin-right: 40px;
	left: 0;
}

.yellow {
	font-weight: 800;
	color: #fedc31;
}

.huandianzhan.yellow::before {
	content: "";
	background: url("~@/assets/images/index/huandianzhanY.png");
	background-size: 100% 100%;
	display: block;
	position: absolute;
	width: 25px;
	height: 15px;
	margin-right: 40px;
	left: 0;
}

.green {
	font-weight: 800;
	color: #36fbfb;
}

.huandianzhan.green::before {
	content: "";
	background: url("~@/assets/images/index/huandianzhanG.png");
	background-size: 100% 100%;
	display: block;
	position: absolute;
	width: 25px;
	height: 15px;
	margin-right: 40px;
	left: 0;
}

.chongdianzhuang.green::before {
	content: "";
	background: url("~@/assets/images/index/chongdianzhuangG.png");
	background-size: 100% 100%;
	display: block;
	position: absolute;
	width: 20px;
	height: 19px;
	margin-right: 35px;
	left: 0;
}

.chongdianzhuang.yellow::before {
	content: "";
	background: url("~@/assets/images/index/chongdianzhuangY.png");
	background-size: 100% 100%;
	display: block;
	position: absolute;
	width: 20px;
	height: 19px;
	margin-right: 35px;
	left: 0;
}

.chongdianzhuang::before {
	content: "";
	background: url("~@/assets/images/index/chongdianzhuang.png");
	background-size: 100% 100%;
	display: block;
	position: absolute;
	width: 20px;
	height: 19px;
	margin-right: 35px;
	left: 0;
}
.vehicleIcon::before {
	content: "";
	background: url("~@/assets/images/comm/vehicleIcon.jpg");
	background-size: 100% 100%;
	display: block;
	position: absolute;
	width: 20px;
	height: 19px;
	margin-right: 35px;
	left: 0;
}

.marsMapBgTop1 {
  width: 214px;
  height: 60px;
  background: url("~@/assets/images/index/marsMapBgTop1.png") repeat;
  background-size: 100% 100%;
  padding-top: 30px;
}
.marsMapBgBottom1 {
  width: 214px;
  height: 95px;
  background: url("~@/assets/images/index/marsMapBgBottom1.png") repeat;
  background-size: 100% 100%;
}
.marsMapBgAll{
  width: 158px;
  height: 138px;
  background: url("~@/assets/images/index/smallGreen.png") repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  padding-top: 30px;
}

.marsMapBg1 {
  width: 214px;
  /* height: 128px; */
  background: url("~@/assets/images/index/mapBgCenter1.png") repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}
</style>
<style lang="scss" scoped>
@import '@/views/vehicles/components/scss/map-com.scss';
.mapChoose {
	position: absolute;
	left: 20px;
	top: 55px;
	color: #eee;

	.title {
		padding: 5px;
		border-top: 1px solid rgba(147, 235, 248, 0.8);
		border-bottom: 1px solid rgba(147, 235, 248, 0.8);
		cursor: pointer;
	}

	.icon {
		font-family: "simsun";
		font-size: 25px;
		margin: 0 11px;
	}

	.superMap {
		min-width: 2008px;
	}
}
</style>