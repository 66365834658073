<template>
	<div class="container">
		<div class="top">
			<part1 />
		</div>
		<div class="bottom">
			<part2 />
		</div>
	</div>
</template>

<script>
import part1 from './components/vehiclesPart/part1.vue'
import part2 from './components/vehiclesPart/part2.vue'

export default {
	data() {
		return {};
	},
	components: {
		part1,
		part2
	},
	props: {
		cdata: {
			type: Object,
			default: () => ({})
		},
	},
	watch: {

	},
	mounted() {

	},
	methods: {}
}
</script>

<style lang="scss" scoped>
.container {
	display: flex;
	flex-direction: column;

	.top {
		width: 100%;
		height: 96.5%;
		display: flex;
		flex-direction: row;
		margin-bottom: 50px;
	}

	.bottom {
		width: 100%;
		height: 96.5%;
		display: flex;
		// margin:100px 0;
	}
}
</style>