<template>
	<div class="map-part">
		<title1 title="交通1指标" subTitle="Traffic Index" />
		<div class="contion">
			<div class="box">
				<divBox class="mt-20" title="电池类型占比">
					<myEchart :visible="batteryTypeShow" :id="'BatteryType'" :options="batteryTypeOptions" />
				</divBox>
			</div>
			<div class="box">
				<divBox title="车辆入网趋势">
					<myEchart :visible="cheliangNetworkAccessShow" :id="'CheliangNetworkAccess'"
						:options="cheliangNetworkAccessOptions" />
				</divBox>
			</div>
			<div class="box">
				<divBox title="车辆品牌">
					<myEchart :visible="cheliangBrandShow" :id="'CheliangBrand'" :options="cheliangBrandOptions" />
				</divBox>
			</div>
			<div class="box">
				<divBox title="车辆型号">
					<myEchart :visible="cheliangModelShow" :id="'CheliangModel'" :options="cheliangModelOptions" />
				</divBox>
			</div>
		</div>
	</div>
</template>

<script>
import divBox from "../common/divBox.vue";
import myEchart from '@/views/components/charts/echarts'
import { dataInterface } from '@/api/dataInterfaceApi';
import title1 from '@/views/exposition/components/title1'
import * as echarts from 'echarts'

export default {
	components: {
		myEchart,
		divBox,
		title1
	},
	data() {
		return {
			bigtitle: {
				CHN: "交通指标",
				ENG: "Traffic indicators",
			},
			// 电池类型占比
			batteryTypeOptions: {},
			batteryTypeShow: false,
			// 车辆品牌
			cheliangBrandOptions: {},
			cheliangBrandShow: false,
			// 车辆车型
			cheliangModelOptions: {},
			cheliangModelShow: false,
			// 车辆入网趋势
			cheliangNetworkAccessOptions: {},
			cheliangNetworkAccessShow: false,
			divflex: "none",
			dataBase: null,

			// 各类型能耗统计
			energySimulationOptions: {},
			energySimulationShow: false,
			energyTypeAt: 0,
			energyType: [{
					label: "各车辆型号平均能耗",
					unit: "度/min",
					value: "quanguo_shengshi-各车辆型号平均能耗",
				},
				{
					label: "各电池类型平均能耗",
					unit: "度/min",
					value: "quanguo_shengshi-各电池类型平均能耗",
				},
			],
			// 各类型度电里程统计
			mileageOptions: {},
			mileageShow: false,
			mileageTypeAt: 0,
			mileageType: [{
					label: "各车辆型号平均度电里程",
					unit: "km/度",
					value: "quanguo_shengshi-各车辆型号平均度电里程",
				},
				{
					label: "各电池类型平均度电里程",
					unit: "km/度",
					value: "quanguo_shengshi-各电池类型平均度电里程",
				},
			],
			// SOC仿真能耗统计
			SOCSimulationOptions: {},
			SOCSimulationShow: false,
			SOCTypeAt: 0,
			SOCType: [{
					label: "仿真平均能耗",
					unit: "度/min",
					value: "quanguo_shengshi-soc仿真平均能耗",
				},
				{
					label: "仿真平均度电里程",
					unit: "km/度",
					value: "quanguo_shengshi-soc仿真平均度电里程",
				},
			],

			// 速度仿真平均度电里程
			mileageConsumptionShow: false,
			mileageConsumptionOptions: {},
			consumptionType: [{
					label: "变化能耗",
					unit: "度/min",
					value: "quanguo_shengshi-速度仿真变化能耗",
				},
				{
					label: "平均度电里程",
					unit: "km/度",
					value: "quanguo_shengshi-速度仿真平均度电里程",
				},
			],
		};
	},
	props: {
		cdata: {
			type: Object,
			default: () => ({}),
		},
	},
	watch: {},
	mounted() {
		this.getData()
	},
	methods: {
		// 刷新接口需要每个子组件封装一个getData();
		getData(data) {
			// 电池类型对比
			this.batteryType(data);
			// 车辆品牌
			this.cheliangBrand(data);
			// 车辆车型
			this.cheliangModel(data);
			// 车辆入网趋势
			this.cheliangNetworkAccess(data);

			this.dataBase = data;
		},
		// 电池类型对比
		batteryType(data = {}) {
			let params = {};
			params = {
				enCode: "quanguo_shengshi-电池类型占比",
				"@ADCODE": (data ? data.province || data.city || data.adcode : "") || "000000",
			};
			let colors = ["#3174FC", "#11B5EF", "#F5EF50", "#91E679", "#3174FC", "#11B5EF", "#F5EF50", "#91E679"]
			dataInterface.dataFaceApi(params).then((res) => {
				if (!res || !res.length) return;
				let data = res;
				let seriesData = [];
				data.map((item, i) => {
					seriesData.push({
						value: parseFloat(item.battery_num || 0) * 100,
						name: item.battery_type + " " + parseFloat(item.battery_num || 0) *
							100 + "%",
						itemStyle: {
							color: colors[i]
						}
					});
				});
				this.batteryTypeOptions = {
					//   backgroundColor:'rgba(0, 0, 0, 0.1)',
					tooltip: {
						trigger: "item",
					},
					legend: {
						// top: "50%",
						// bottom: 0,
						left: "right",
						orient: "vertical",
						itemWidth: 10,
						itemHeight: 10,
						textStyle: {
							color: "#fff",
						},
					},
					series: [{
						name: "",
						type: "pie",
						left: "10%",
						radius: ["20%", "50%"],
						avoidLabelOverlap: false,
						emphasis: {
							label: {
								show: true,
								fontSize: 30,
								// fontWeight: "bold",
							},
						},
						data: seriesData,
					}, ],
				};
				this.batteryTypeShow = true;
			});
		},

		// 车辆品牌
		cheliangBrand(data = {}) {
			let params = {};
			let query = this.$route.query;
			params = {
				enCode: "quanguo_shengshi-车辆品牌",
				"@VEHICLE_TYPE": query.vehicle == "1" ?
					"乘用车" :
					query.vehicle == "2" ?
					"商用车" :
					"全部",
				"@ADCODE": (data ? data.province || data.city || data.adcode : "") || "000000",
			};
			dataInterface.dataFaceApi(params).then((res) => {
				if (!res || !res.length) return;
				let data = res.sort(function(a, b) {
					return b.vehicle_num - a.vehicle_num;
				});
				let xAxisData = [];
				let seriesData = [];
				data.map((item) => {
					xAxisData.push(item.vehicle_brand),
						seriesData.push({
							value: parseInt(item.vehicle_num, 10),
							// itemStyle: {
							// 	borderRadius: 5,
							// }
						});
				});
				this.cheliangBrandOptions = {
					title: {
						text: "辆",
					},
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
						},
					},
					xAxis: [{
						type: "category",
						data: xAxisData,
					}, ],
					yAxis: [{
						type: "value",
					}, ],
					series: [{
						name: "数量",
						type: "bar",
						barWidth: 20,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: "#0BBCE9"
								},
								{
									offset: 1,
									color: 'rgba(11,47,57,0.1)'
								},
							]),
						},
						emphasis: {
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: "#0BBCE9"
									},
									{
										offset: 1,
										color: 'rgba(11,47,57,0.1)'
									},
								]),
							},
						},
						data: seriesData,
					}, ],
				};
				this.cheliangBrandShow = true;
			});
		},

		// 车辆车型
		cheliangModel(data = {}) {
			let params = {};
			let query = this.$route.query;
			params = {
				enCode: "quanguo_shengshi-车辆车型",
				"@VEHICLE_TYPE": query.vehicle == "1" ?
					"乘用车" :
					query.vehicle == "2" ?
					"商用车" :
					"全部",
				"@ADCODE": (data ? data.province || data.city || data.adcode : "") || "000000",
			};
			dataInterface.dataFaceApi(params).then((res) => {
				if (!res || !res.length) return;
				let data = res.sort(function(a, b) {
					return b.vehicle_num - a.vehicle_num;
				});
				let xAxisData = [];
				let seriesData = [];
				data.map((item) => {
					xAxisData.push(item.vehicle_model),
						seriesData.push({
							value: parseInt(item.vehicle_num, 10),
							// itemStyle: {
							// 	borderRadius: 5,
							// }
						});
				});
				this.cheliangModelOptions = {
					title: {
						text: "辆",
						top: "3%",
						left: "2%",
						textStyle: {
							color: "#b3b3c9",
						},
					},
					tooltip: {
						trigger: "axis",
						axisPointer: {
							type: "shadow",
						},
					},
					// grid: {
					// 	left: '3%',
					// 	right: '4%',
					// 	bottom: '3%',
					// 	containLabel: true
					// },
					xAxis: [{
						type: "category",
						data: xAxisData,
					}, ],
					yAxis: [{
						type: "value",
					}, ],
					series: [{
						name: "数量",
						type: "bar",
						barWidth: 20,
						itemStyle: {
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
									offset: 0,
									color: "#18EFC5"
								},
								{
									offset: 1,
									color: "rgba(23,69,61,0.1)"
								},
							]),
						},
						emphasis: {
							itemStyle: {
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: "#18EFC5"
									},
									{
										offset: 1,
										color: "rgba(23,69,61,0.1)"
									},
								]),
							},
						},
						data: seriesData,
					}, ],
				};
				this.cheliangModelShow = true;
			});
		},

		// 车辆入网趋势
		cheliangNetworkAccess(data = {}) {
			let params = {};
			let query = this.$route.query;
			params = {
				enCode: "quanguo_shengshi-车辆新增入网趋势",
				"@VEHICLE_TYPE": query.vehicle == "1" ?
					"乘用车" :
					query.vehicle == "2" ?
					"商用车" :
					"全部",
				"@ADCODE": (data ? data.province || data.city || data.adcode : "") || "000000",
			};
			dataInterface
				.dataFaceApi(params)
				.then((res) => {
					if (!res) return;
					let data = res;
					let xAxis = [];
					let yAxis = [];
					data.map((item) => {
						xAxis.push(item.cur_month);
						yAxis.push(item.new_net_vehicle_number);
					});
					this.cheliangNetworkAccessOptions = {
						title: {
							text: "辆",
							top: "3%",
							left: "2%",
							textStyle: {
								color: "#b3b3c9",
							},
						},
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: "cross",
								label: {
									backgroundColor: "#6a7985",
								},
							},
						},
						xAxis: [{
							type: "category",
							boundaryGap: false,
							data: xAxis,
						}, ],
						yAxis: [{
							type: "value",
						}, ],
						series: [{
							name: "入网车辆",
							type: "line",
							stack: "Total",
							smooth: true,
							lineStyle: {
								width: 2,
								color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
										offset: 0,
										color: "RGBA(61, 161, 255, 1)",
									},
									{
										offset: 1,
										color: "RGBA(52, 41, 240, 1)",
									},
								]),
							},
							areaStyle: {
								opacity: 0.8,
								color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
										offset: 0,
										color: "rgba(101, 97, 240, 0.42)",
									},
									{
										offset: 1,
										color: "rgba(118, 206, 255, 0)",
									},
								]),
							},
							emphasis: {
								focus: "series",
							},
							data: yAxis,
						}, ],
					};
					this.cheliangNetworkAccessShow = true;
				})
				.catch((err) => {
					console.log(err);
				});
		},
		// 各类型能耗统计
		setEnergyTypeAt(i) {
			this.energyTypeAt = i;
			this.energySimulation();
		},
		energySimulation() {
			let data = this.dataBase;
			let params = {};
			let query = this.$route.query;
			params = {
				enCode: this.energyType[this.energyTypeAt].value,
				"@VEHICLE_TYPE": query.vehicle == "1" ?
					"乘用车" :
					query.vehicle == "2" ?
					"商用车" :
					"全部",
				"@ADCODE": (data ? data.province || data.city || data.adcode : "") || "000000",
			};
			this.energySimulationShow = false;
			dataInterface
				.dataFaceApi(params)
				.then((res) => {
					if (!res || !res.length) return;
					// 排序
					let data = res.sort(function(a, b) {
						return (
							new Date(a.soc_month.replace("-", "/")) -
							new Date(b.soc_month.replace("-", "/"))
						);
					});
					// let months = []
					let monthData = {};
					let batterys = [];
					data.map((item) => {
						if (!monthData[item.soc_month]) {
							monthData[item.soc_month] = [];
						}
						monthData[item.soc_month].push(item);
						// 所有电池类型
						if (!batterys.includes(item.battery_type || item.cat_model)) {
							batterys.push(item.battery_type || item.cat_model);
						}
					});
					let xAxis = Object.keys(monthData);
					let seriesData = [];
					batterys.map((battery) => {
						let sData = {
							name: battery,
							type: "line",
							showSymbol: false,
							data: [],
						};
						seriesData.push(sData);
					});
					xAxis.map((item) => {
						seriesData.map((sData) => {
							let a =
								monthData[item].find((i) => {
									return (i.battery_type || i.cat_model) == sData.name;
								}) || {};
							sData.data.push(a.avg_energy_consumption || 0);
						});
					});
					this.energySimulationOptions = {
						color: ["#1968FF", "#3DDDFF", "#FFFA6D", "#77E8A9"],
						title: {
							text: this.energyType[this.energyTypeAt].unit,
						},
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: "cross",
								label: {
									backgroundColor: "#6a7985",
								},
							},
						},
						xAxis: [{
							type: "category",
							boundaryGap: false,
							data: xAxis,
						}, ],
						yAxis: [{
							type: "value",
						}, ],
						series: seriesData,
					};
					this.energySimulationShow = true;
				})
				.catch((err) => {
					console.log(err);
				});
		},

		// 各类型度电里程统计
		setMileageTypeAt(i) {
			this.mileageTypeAt = i;
			this.mileageSimulation();
		},
		mileageSimulation() {
			let data = this.dataBase;
			let params = {};
			let query = this.$route.query;
			params = {
				enCode: this.mileageType[this.mileageTypeAt].value,
				"@VEHICLE_TYPE": query.vehicle == "1" ?
					"乘用车" :
					query.vehicle == "2" ?
					"商用车" :
					"全部",
				"@ADCODE": (data ? data.province || data.city || data.adcode : "") || "000000",
			};
			this.mileageShow = false;
			dataInterface
				.dataFaceApi(params)
				.then((res) => {
					if (!res || !res.length) return;
					// 排序
					let data = res.sort(function(a, b) {
						return (
							new Date(a.soc_month.replace("-", "/")) -
							new Date(b.soc_month.replace("-", "/"))
						);
					});
					// let months = []
					let monthData = {};
					let batterys = [];
					data.map((item) => {
						if (!monthData[item.soc_month]) {
							monthData[item.soc_month] = [];
						}
						monthData[item.soc_month].push(item);
						// 所有电池类型
						if (!batterys.includes(item.battery_type || item.cat_model)) {
							batterys.push(item.battery_type || item.cat_model);
						}
					});
					let xAxis = Object.keys(monthData);
					let seriesData = [];
					batterys.map((battery) => {
						let sData = {
							name: battery,
							type: "line",
							showSymbol: false,
							data: [],
						};
						seriesData.push(sData);
					});
					xAxis.map((item) => {
						seriesData.map((sData) => {
							let a =
								monthData[item].find((i) => {
									return (i.battery_type || i.cat_model) == sData.name;
								}) || {};
							sData.data.push(a.avg_mileage_per_kwh || 0);
						});
					});
					this.mileageOptions = {
						color: ["#1968FF", "#3DDDFF", "#FFFA6D", "#77E8A9"],
						title: {
							text: this.mileageType[this.mileageTypeAt].unit,
						},
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: "cross",
								label: {
									backgroundColor: "#6a7985",
								},
							},
						},
						xAxis: [{
							type: "category",
							boundaryGap: false,
							data: xAxis,
						}, ],
						yAxis: [{
							type: "value",
						}, ],
						series: seriesData,
					};
					this.mileageShow = true;
				})
				.catch((err) => {
					console.log(err);
				});
		},

		// SOC仿真能耗统计
		setSOCTypeAt(i) {
			this.SOCTypeAt = i;
			this.SOCSimulation();
		},
		SOCSimulation() {
			let data = this.dataBase;
			let params = {};
			let query = this.$route.query;
			params = {
				enCode: this.SOCType[this.SOCTypeAt].value,
				"@VEHICLE_TYPE": query.vehicle == "1" ?
					"乘用车" :
					query.vehicle == "2" ?
					"商用车" :
					"全部",
				"@ADCODE": (data ? data.province || data.city || data.adcode : "") || "000000",
			};
			this.SOCSimulationShow = false;
			dataInterface
				.dataFaceApi(params)
				.then((res) => {
					if (!res || !res.length) return;
					let data = res.sort(function(a, b) {
						return a.soc - b.soc;
					});
					let xAxis = [];
					let yAxis = [];
					data.map((item) => {
						xAxis.push(item.soc);
						yAxis.push(
							parseFloat(
								item.energy_consumption ||
								item.avg_change_time ||
								item.avg_per_kwh_mileage
							)
						);
					});
					this.SOCSimulationOptions = {
						title: {
							text: this.SOCType[this.SOCTypeAt].unit,
						},
						tooltip: {
							trigger: "axis",
							axisPointer: {
								type: "cross",
								label: {
									backgroundColor: "#6a7985",
								},
							},
						},
						xAxis: [{
							type: "category",
							boundaryGap: false,
							data: xAxis,
						}, ],
						yAxis: [{
							type: "value",
						}, ],
						series: [{
							name: this.SOCType[this.SOCTypeAt].label,
							type: "line",
							// stack: 'Total',
							smooth: true,
							showSymbol: false,
							lineStyle: {
								width: 2,
								color: "#5FF8FF",
							},
							emphasis: {
								focus: "series",
							},
							data: yAxis,
						}, ],
					};
					this.SOCSimulationShow = true;
				})
				.catch((err) => {
					console.log(err);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
// @import '../scss/chd-com.scss';

.contion {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: stretch;
	justify-content: space-between;
}

.box {
	margin-top: 10px;
	width: 430px;
	height: 300px;
	margin-bottom: 140px;
}
</style>