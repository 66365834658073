<template>
    <div class="vehicle-map">
      <div class="top">
        <target/>
      </div>
      <div class="left">
        <title1 title="综合态势" :isBg="false" fontSize="32"/>
        <div
          v-if="zongheAllData && zongheAllData.length"
          class="flex zonghe">
          <div class="item">
              <div class="item_box" v-for="(item ,index) in zongheAllData" :key="'zongheAllData' + index">
                <span class="item_value1">{{item.value}}</span>
                <div class="item_img1"></div>
                <!-- <img class="item_img" src="@/assets/images/index/bottom_1.png" alt=""> -->
                <span>{{item.label}}</span>
              </div>
          </div>
        </div>
        <divBox title="近7日态势">
          <div  v-if="zongheSevenDaysData && zongheSevenDaysData.length" class="flex zonghe">
            <div class="item">
              <!-- v-if="item.label != '车辆活跃度'" v-for中不使用v-if 通过computed过滤数据 --> 
                <div class="item_box" v-for="(item ,index) in removeData" :key="'zongheSevenDaysData' + index">
                  <span class="item_value">{{item.value}}</span>
                  <div class="item_img"></div>
                  <!-- <img class="item_img" src="@/assets/images/index/bottom_1.png" alt=""> -->
                  <span>{{item.label}}</span>
                </div>
            </div>
          </div>
        </divBox>
      </div>
      <div class="right">
          <title1 title="车辆分布地" :isBg="false" fontSize="32"/>
        <!-- <divBox title="车辆分布地" style="flex: 3;height:800px;" textColor='color:#ECFDFF;'> -->
          <div style="height: 740px; flex: 6">
            <myEchart
              :style="{ height: `${echartHeight}%` }"
              :visible="vehicleDistributionShow"
              :id="'VehicleDistribution'"
              :options="vehicleDistributionOptions"
            />
          </div>
      </div>
    </div>
  </template>
  
  <script>
    // import Map2D from "./components/map/2dMap.vue";
    // import Map3D from "./components/map/3dMap.vue";
    import divBox from "./components/common/divBox.vue";
    import target from "./components/vehiclesPart/target.vue";
    import myEchart from '@/views/components/charts/echarts'
//   import carsmap from "../map/carsmap";
    import { dataInterface } from "@/api/dataInterfaceApi";
    import title1 from '@/views/exposition/components/title1'
    import * as echarts from 'echarts'
  export default {
    components: {
    //   Map2D,
    //   Map3D,
      divBox,
      myEchart,
    //   carsmap,
      target,
      title1
    },
    data() {
      return {
        showBack: false,
        showVehicleControl: false, // 显示多车监控
        showMapBtn: false,
        changeMapBtn: false,
  
        zongheSevenDaysData: [], // 综合7天数据
        zongheAllData: [], // 综合累计
        // 车辆占比
        vehicleProportionOptions: {},
        vehicleProportionShow: false,
        // 乘用车&商用车换电对比
        cheliangComparisonOptions: {},
        cheliangComparisonShow: false,
        // 车辆分布地
        vehicleDistributionOptions: {},
        vehicleDistributionShow: false,
        echartHeight: 100,
        // 换电次数时间分布
        powerChangeTimeOptions: {},
        powerChangeTimeShow: false,
      };
    },
    props: {
      type: {
        type: String,
        default: () => "",
      },
    },
    computed: {
      removeData(){
        console.log(this.zongheSevenDaysData, '我的数据')
        let data = this.zongheSevenDaysData.filter((item) => {
          return item.label != '车辆活跃度'
        })
        return data
      }
    },
    watch: {
      type: {
        handler(newData) {
          console.log("newDatanewDatanewData", newData);
          if (newData && newData == "nationwideSite") {
            this.showMapBtn = true;
          }
        },
        immediate: true,
        deep: true,
      },
    },
    mounted() {
      console.log("showMapBtn", this.showMapBtn);
      this.changeMapBtn =
        localStorage.getItem("changeMapBtn") == "true" ? true : false;
      this.showVehicleControl = this.$route.name == "newVehicleControl";
      // 返回
      this.showBack = this.$route.name !== "vehicleMap";
      if (this.showVehicleControl) {
        this.$nextTick(() => {
          this.$refs.carsmap.init(
            this.$route.query.cityName,
            "",
            this.$route.query.value
          );
        });
      }
      this.getData()
    },
    methods: {
      getData(data) {
        console.log("adfadfadsf", data);
        // 综合态势7天
        this.zongheSevenDays(data);
        // 综合态势累计
        this.zongheAll(data);
        // 车辆类型占比
        this.cheliangProportion(data);
        // 乘用车&商用车换电对比
        this.cheliangComparison(data);
        // 车辆分布地
        this.vehicleDistribution(data);
        // 换电次数时间分布
        this.powerChangeTime(data);
      },
      // 综合态势7天
      zongheSevenDays(data = {}) {
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: "quanguo_shengshi-综合态势7",
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
          "@OPER_TYPE": "充换电",
        };
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res) return;
            let data = (res && res[0]) || {};
            this.zongheSevenDaysData.push({
              value: data.vehicle_avtive_num,
              label: "活跃车辆数",
              color: "#36FBFB",
            });
            this.zongheSevenDaysData.push({
              value: data.new_acc_vehilce_num,
              label: "新增入网车辆",
              color: "#43FFB9",
            });
            this.zongheSevenDaysData.push({
              value: data.vehicle_active_rate,
              label: "车辆活跃度",
              color: "#FFFFFF",
            });
            this.zongheSevenDaysData.push({
              value:
                (parseFloat(data.exchange_mileage || 0) / 10000).toFixed(2) + "w",
              label: "行驶里程",
              color: "#179DFF",
            });
            this.zongheSevenDaysData.push({
              value: data.power_exchange_num,
              label: "充换电次数",
              color: "#36FBFB",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 综合态势累计
      zongheAll(data = {}) {
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: "quanguo_shengshi-综合态势lj",
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res) return;
            let data = (res && res[0]) || {};
            this.zongheAllData.push({
              value: data.acc_exchange_vehicle_num,
              label: "累计充换电车辆",
              color: "#36FBFB",
            });
            this.zongheAllData.push({
              value:
                (parseFloat(data.acc_exchange_elect || 0) / 10000).toFixed(2) +
                "w",
              label: "累计充换电电量",
              color: "#43FFB9",
            });
            this.zongheAllData.push({
              value:
                (parseFloat(data.acc_exchange_mileage || 0) / 10000).toFixed(2) +
                "w",
              label: "累计行驶里程",
              color: "#FFFFFF",
            });
            this.zongheAllData.push({
              value: data.acc_power_exchange_num,
              label: "累计充换电次数",
              color: "#179DFF",
            });
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      // 车辆类型占比
      cheliangProportion(data = {}) {
        let params = {};
        // let query = this.$route.query
        params = {
          enCode: "quanguo-车辆类型占比",
          // '@VEHICLE_TYPE': query.vehicle == '1' ? '乘用车' : (query.vehicle == '2' ? '商用车' : '全部'),
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        dataInterface.dataFaceApi(params).then((res) => {
          if (!res || !res.length) return;
          let data = res || [];
          let data1 = (parseFloat((data[0] && data[0].value) || 0) * 100).toFixed(
            2
          );
          let data2 = (parseFloat((data[1] && data[1].value) || 0) * 100).toFixed(
            2
          );
          this.vehicleProportionOptions = {
            color: ["#1083F9", "#77E8A9"],
            tooltip: {
              trigger: "item",
            },
            legend: {
              top: "40%",
              left: "left",
              orient: "vertical",
              // icon: 'circle',
              itemWidth: 10,
              itemHeight: 10,
              textStyle: {
                color: "#fff",
              },
            },
            series: [
              {
                name: "",
                type: "pie",
                left: "20%",
                radius: ["30%", "70%"],
                avoidLabelOverlap: false,
                emphasis: {
                  label: {
                    show: true,
                    fontSize: 40,
                    fontWeight: "bold",
                  },
                },
                data: [
                  { value: data1, name: `乘用车: ${data1}%` },
                  { value: data2, name: `商用车: ${data2}%` },
                ],
              },
            ],
          };
          this.vehicleProportionShow = true;
          // // 乘用车占比（需要乘以100得到占比）
          // this.VehicleProportion.passenger_rate = data.passenger_rate
          // // 商用车占比（需要乘以100得到占比）
          // this.VehicleProportion.commercial_rate = data.commercial_rate
        });
      },
  
      // 乘用车&商用车换电对比
      cheliangComparison(data = {}) {
        let params = {};
        // let query = this.$route.query
        params = {
          enCode: "quanguo-乘用车商用车换电对比",
          // '@VEHICLE_TYPE': query.vehicle == '1' ? '乘用车' : (query.vehicle == '2' ? '商用车' : '全部'),
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        dataInterface.dataFaceApi(params).then((res) => {
          if (!res) return;
          let data = res || [];
          let typeL = [
            "vehicle_power_exchange_up_num",
            "consumption_elect",
            "exchange_mileage",
          ];
          let unit = ["辆", "w度", "w公里"];
          let typeL1 = [
            "vehicle_power_exchange_up_num_rate",
            "consumption_elect_rate",
            "exchange_mileage_rate",
          ];
          this.cheliangComparisonOptions = {
            tooltip: {
              trigger: "axis",
              formatter: function (params) {
                return (
                  params[0].name +
                  "<br>" +
                  (data[0].vehicle_type == "passenger_vehicle"
                    ? "乘用车："
                    : "商用车：") +
                  params[0].value +
                  "%&nbsp;&nbsp;&nbsp;&nbsp;" +
                  (params[0].dataIndex > 0
                    ? (data[0][typeL[params[0].dataIndex]] / 10000).toFixed(2)
                    : data[0][typeL[params[0].dataIndex]]) +
                  `${unit[params[0].dataIndex]}<br>` +
                  (data[1].vehicle_type == "passenger_vehicle"
                    ? "乘用车："
                    : "商用车：") +
                  params[1].value +
                  "%&nbsp;&nbsp;&nbsp;&nbsp;" +
                  (params[0].dataIndex > 0
                    ? (data[1][typeL[params[0].dataIndex]] / 10000).toFixed(2)
                    : data[1][typeL[params[0].dataIndex]]) +
                  `${unit[params[0].dataIndex]}`
                );
              },
            },
            legend: {
              right: "left",
              itemWidth: 10,
              itemHeight: 10,
              textStyle: {
                color: "#fff",
              },
            },
            xAxis: [
              {
                type: "category",
                data: ["活跃车辆数", "消费电量", "行驶里程"],
                splitLine: {
                  show: false,
                },
                axisLabel: {
                  color: "#fff",
                },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: data.map((item, i) => {
              return {
                name: `${
                  item.vehicle_type == "passenger_vehicle" ? "乘用车" : "商用车"
                }`,
                type: "bar",
                barWidth: 20,
                stack: "cheliangComparison",
                emphasis: {
                  focus: "series",
                },
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: i == 0 ? "#36A1FF" : "#00FFD6" },
                    { offset: 1, color: i == 0 ? "#1968FF" : "#3DCAFF" },
                  ]),
                },
                data: typeL1.map((type) => {
                  return (parseFloat(item[type] || 0) * 100).toFixed(2);
                }),
              };
            }),
          };
          this.cheliangComparisonShow = true;
          // // 乘用车占比（需要乘以100得到占比）
          // this.VehicleProportion.passenger_rate = data.passenger_rate
          // // 商用车占比（需要乘以100得到占比）
          // this.VehicleProportion.commercial_rate = data.commercial_rate
        });
      },
  
      // 车辆分布地
      vehicleDistribution(data = {}) {
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: "quanguo_shengshi-车辆分布地",
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res || !res.length) return;
            let data = res.sort(function (a, b) {
              return parseFloat(a.vehicle_num) - parseFloat(b.vehicle_num);
            });
            let xAxis = [];
            let yAxis = [];
            this.echartHeight = data.length < 10 ? data.length * 10 : 100;
            data.map((item) => {
              xAxis.push(item.address || "");
              yAxis.push({
                value: item.vehicle_num || "",
                itemStyle: {
                  borderRadius: 3,
                },
              });
            });
            this.vehicleDistributionOptions = {
              // tooltip: {
              //   trigger: "axis",
              //   axisPointer: {
              //     type: "shadow",
              //   },
              // },
              legend: { hidden: true },
              grid: {
                top: "0",
              },
              xAxis: {
                type: "value",
                show: false,
                boundaryGap: [0, 0.01],
              },
              yAxis: {
                type: "category",
                data: xAxis,
              },
              series: [
                {
                  name: "车辆：",
                  type: "bar",
                  barWidth: 15,
                  label: {
                    show: true,
                    position: "right",
                  },
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      { offset: 0, color: "#3EFFF4" },
                      { offset: 1, color: "#B2FEFC" },
                    ]),
                  },
                  data: yAxis,
                },
              ],
            };
            this.vehicleDistributionShow = true;
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      // 换电次数时间分布
      powerChangeTime(data = {}) {
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: "quanguo_shengshi-充换电次数时间分布",
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
          "@OPER_TYPE": "充换电",
        };
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res || !res.length) return;
            // 时间排序
            let data = res.sort(function (a, b) {
              return a.cur_hour - b.cur_hour;
            });
            let xAxis = [];
            let seriesData1 = [];
            let seriesData2 = [];
            data.map((item) => {
              xAxis.push(item.cur_hour);
              seriesData1.push(item.exchange_num);
              seriesData2.push(item.charge_num);
            });
            this.powerChangeTimeOptions = {
              colors: ["#1CD3F3", "#7C6AF2"],
              title: {
                text: "次",
              },
              legend: {
                right: "left",
                itemWidth: 10,
                itemHeight: 10,
                textStyle: {
                  color: "#fff",
                },
                data: ["充电次数", "换电次数"],
              },
              tooltip: {
                trigger: "axis",
              },
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: xAxis,
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              series: [
                {
                  name: "充电次数",
                  type: "line",
                  // stack: 'Total',
                  smooth: true,
                  lineStyle: {
                    width: 2,
                    color: "#1CD3F3",
                  },
                  itemStyle: {
                    normal: {
                      color: "#1CD3F3",
                    },
                  },
                  showSymbol: false,
                  areaStyle: {
                    opacity: 0.8,
                    color: "rgba(28, 211, 243, .2)",
                  },
                  emphasis: {
                    focus: "series",
                  },
                  data: seriesData2,
                },
                {
                  name: "换电次数",
                  type: "line",
                  // stack: 'Total',
                  smooth: true,
                  lineStyle: {
                    width: 2,
                    color: "#7C6AF2",
                  },
                  itemStyle: {
                    normal: {
                      color: "#7C6AF2",
                    },
                  },
                  showSymbol: false,
                  areaStyle: {
                    opacity: 0.8,
                    color: "rgba(124, 106, 242, .2)",
                  },
                  emphasis: {
                    focus: "series",
                  },
                  data: seriesData1,
                },
              ],
            };
            this.powerChangeTimeShow = true;
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      // clickMapBtn() {
      //   this.changeMapBtn = !this.changeMapBtn;
      //   localStorage.setItem("changeMapBtn", this.changeMapBtn);
      // },
  
      // goback() {
      //   window.history.back(-1);
      // },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import './components/scss/chd-com.scss';
  
  @keyframes breath_light {
              0% {
                  filter: brightness(100%);
              }
              50% {
                  filter: brightness(180%);
              }
              100% {
                  filter: brightness(100%);
              }
          }
  .item{
    width:350px;
    height:375px;
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    .item_box{
      width: 150px;
      height:155px;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      .item_value{
        margin-bottom:10px;
        line-height: 5px;
        font-size:30px;
        color:#36FBFB;
        display:block;
        text-align:center;
        font-family: 'DS-Digital-Bold';
      }
      .item_img{
        width:100px;
        height:100px;
        background:url('~@/assets/images/index/bottom_2.png') no-repeat ;
        background-size: 100% 100%;
        animation: breath_light 3s ease infinite;
      }
      .item_value1{
        margin-bottom:-10px;
        line-height: 5px;
        font-size:30px;
        color:#36FBFB;
        text-align:center;
        font-family: 'DS-Digital-Bold';
      }
      .item_img1{
        width:100px;
        height:100px;
        background:url('~@/assets/images/index/bottom_1.png') no-repeat ;
        background-size: 100% 100%;
        animation: breath_light 3s ease infinite;
      }
  }
  } 
  .vehicle-map {
    width: 100%;
    height: 980px;
    // margin-top: 20px;
    position: relative;
    background-size: 80% 60%;
    .left,
    .right {
      position: absolute;
      // flex-shrink: 0;
      top: 140px;
      width: 400px;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 20px;
      z-index: 10;
      // :first-child {
      //   margin-top: 0;
      // }
      > div {
        margin-top: 20px;
        // flex: 1;
      }
    }
    .left {
      left: 0;
      .zonghe {
        flex-wrap: wrap;
        // justify-content: center;
        align-items: center;
        height: 40%;
        // padding: 15px 0;
        > div {
          // flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          // align-items: center;
          // padding-left: 40px;
          .label {
            font-size: 14px;
            white-space: nowrap;
          }
          .value {
            font-size: 20px;
            font-weight: bold;
          }
        }
      }
    }
    .right {
      right: 0;
      // height: 45%;
      // height: 820px;
    }
    .center {
      // flex: 1;
      width: 100%;
      height: 100%;
      position: relative;
      top: 0;
      .goback {
        position: absolute;
        top: 285px;
        left: 450px;
        width: 100px;
        height: 50px;
        // border: 1px solid #1A7683;
        background: linear-gradient(
          180deg,
          rgba(14, 134, 139, 0.88) 0%,
          rgba(0, 43, 41, 0.6) 100%
        );
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        .location {
          width: 20px;
          height: 20px;
          margin-right: 15px;
          transform: rotate(180deg);
        }
      }
    }
  }
  .show2dBtn {
    background: url("~@/assets/images/index/3d_1.png");
    background-size: 100% 100%;
    display: block;
    position: fixed;
    width: 20px;
    height: 20px;
    margin-right: 40px;
    top: 30px;
    right: 60px;
    cursor: pointer;
  
    &.show3dBtn {
      background: url("~@/assets/images/index/2d_1.png");
      background-size: 100% 100%;
    }
  }
  </style>
  