<template>
	<div class="vehicles expositionCom">
		<div class="center vehicle-map">
			<div v-if="!showVehicleControl" class="center" style="position: relative">
				<Map2D v-if="!changeMapBtn" />
				<!-- <Map2D  v-if="showMap"/> -->
				<!-- <VehiclesweixinMap ref="carsmap" v-if="showMap"/> -->
				<!-- <vehicles3D  v-if="!changeMapBtn && !showMap"/> -->
				
				<!-- <Map3D v-show="changeMapBtn" /> -->
				
				<div v-if="showBack" class="goback" @click="goback">
					<!-- <img class="location" src="@/assets/images/site/arrow_small.png" alt="" /> -->
					返回
				</div>
				<!-- 2d/3d 切换 -->
				<div :class="changeMapBtn ? 'show2dBtn show3dBtn' : 'show2dBtn'" @click="clickMapBtn"></div>
			</div>
			<div v-else class="center" style="position: relative">
				<!-- <div v-show="showBack" class="goback" @click="goback"> -->
					<!-- <img class="location" src="@/assets/images/site/arrow_small.png" alt="" /> -->
					<!-- 返回
				</div> -->
				<!-- <carsmap ref="carsmap"></carsmap> -->
			</div>
		</div>
		<div class="chd-l-bg"></div>
		<div class="chd-r-bg"></div>
		<div class="com-left">
			<Left />
		</div>
		<div class="com-main">
			<Center />
		</div>
		<div class="com-right">
			<Right />
		</div>
	</div>
</template>

<script>
import Map2D from "@/views/components/map/2dMap.vue";
// import Map3D from "./components/map/3dMap";
// import carsmap from "./components/map/carsmap";
import Left from './vehicles/left.vue'
import Right from './vehicles/right.vue'
import Center from './vehicles/center.vue'
// import VehiclesweixinMap from '@/views/components/VehiclesweixinMap'
// import vehicles3D from '@/views/components/vehicles3D.vue'
export default {
	data() {
		return {
			showBack: false,
			showVehicleControl: false, // 显示多车监控
			showMapBtn: false,
			changeMapBtn: false,
			pageInterval: null,
			intervalNum: 10,
			intervalNumSet: 10,
			showMap:  localStorage.getItem('showMap') || false,
			// localStorageData: localStorage.getItem('showMap') || '',
		};
	},
	components: {
		Left,
		Right,
		Center,
		Map2D,
		// Map3D,
		// carsmap,
		// VehiclesweixinMap,
		// vehicles3D
	},
	props: {
		// type: {
		//   type: String,
		//   default: ''
		// }     
	},
	created(){
		console.log(localStorage.getItem('showMap'),'我存的数据l');
		this.showMap = localStorage.getItem('showMap') == 'true' ? true : false;

	},
	// watch: {
	// 	type: {
	// 		handler(newData, oldData, ) {
	// 			console.log("newDatanewDatanewData", newData);
	// 			if (newData && newData == "nationwideSite") {
	// 				this.showMapBtn = true;
	// 			}
	// 		},
	// 		immediate: true,
	// 		deep: true,
	// 	},
	// },
	mounted() {
		// this.$bus.$on('showMap', () => {
		// 	this.showMap = localStorage.getItem('showMap') == 'true' ? true : false;
		// })
		document.title = '移动能源光储充换车云一体化大屏'
		let {
			fromUser,
			scaleY
		} = this.$route.query
		fromUser ? sessionStorage.setItem('fromUser', fromUser) : ''
		scaleY ? localStorage.setItem('bigScreen::scaleY', scaleY) : ''

		if (this.$route.query && this.$route.query.province) {
			console.log('省')
			this.adcode = this.$route.query.province;
			// this.$EventBus.$emit('update', {
			// 	province: this.adcode
			// });
		} else if (this.$route.query && this.$route.query.city) {
			console.log('市')
			this.adcode = this.$route.query.city;
			// this.$EventBus.$emit('update', {
			// 	city: this.adcode
			// });
		} else if (this.$route.query && this.$route.query.district) {
			console.log('区')
			this.adcode = this.$route.query.district;
			// this.$EventBus.$emit('update', {
			// 	district: this.adcode
			// });
		} else {
			console.log('省市区')
			// this.type = 'nationwideSite'
			// this.$EventBus.$emit('update', '');
		}
		console.log("showMapBtn", this.showMapBtn);
		this.changeMapBtn =
			localStorage.getItem("changeMapBtn") == "true" ? true : false;
		this.showVehicleControl = this.$route.name == "newVehicleControl";
		console.log(this.showVehicleControl, 2222222222)
		// 返回
		this.showBack = this.$route.name !== "vehicles";
		if (this.showVehicleControl) {
			this.$nextTick(() => {
				this.$refs.carsmap.init(
					this.$route.query.cityName,
					"",
					this.$route.query.value
				);
			});
		}
		this.pageInterval = null
		// if (sessionStorage.getItem('fromUser') != 'guizhou') {
		// 	document.onmousemove = (event => {
		// 		this.intervalNum = this.intervalNumSet
		// 	})
		// }
	},
	methods: {
		clickMapBtn() {
			this.changeMapBtn = !this.changeMapBtn;
			localStorage.setItem("changeMapBtn", this.changeMapBtn);
			console.log('切换3D')
		},

		goback() {
			window.history.back(-1);
		},
	},
	destroyed() {
		clearInterval(this.pageInterval)
	}
};
</script>

<style lang="scss" scoped>
@import '../views/vehicles/components/scss/comm.scss';

.vehicles {
	position: relative;
	background: url('~@/assets/images/index/allmap_bottom.png');
	background-size: 100% 100%;

	.center {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	.chd-l-bg,
	.chd-r-bg {
		position: absolute;
		height: 100%;
		width: 1436px;
	}

	.chd-l-bg {
		left: 0;
		background: url('~@/assets/images/exposition/chd-l-bg.png');
		background-size: 100% 100%;
		z-index: 1;
		opacity: 0.9;
	}

	.chd-r-bg {
		right: 0;
		background: url('~@/assets/images/exposition/chd-r-bg.png');
		background-size: 100% 100%;
		z-index: 1;
		opacity: 0.8;
	}

	.com-left,
	.com-right {
		z-index: 1;
	}

	// .com-left{
	//     background: url('~@/assets/images/index/LightGradient_left.png') no-repeat;
	// }
	// .com-right{
	//     background: url('~@/assets/images/index/LightGradient_right.png');
	// }

}

.item {
	width: 350px;
	height: 375px;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	.item_box {
		width: 150px;
		height: 175px;
		display: flex;
		flex-direction: column;
		align-items: center;

		.item_value {
			width: 100px;
			height: 100px;
			margin-bottom: 10px;
			font-size: 30px;
			color: #179DFF;
			display: block;
			background: url('~@/assets/images/index/bottom_2.png') no-repeat;
			background-size: 100% 100%;
			text-align: center;
			animation: breath_light 3s ease infinite;
		}

		.item_value1 {
			width: 100px;
			height: 100px;
			margin-bottom: 10px;
			font-size: 30px;
			color: #179DFF;
			display: block;
			background: url('~@/assets/images/index/bottom_1.png') no-repeat;
			background-size: 100% 100%;
			text-align: center;
			animation: breath_light 3s ease infinite;
		}
	}
}

.vehicle-map {
	width: 100%;
	height: 980px;
	// margin-top: 20px;
	position: relative;
	//   background: url(~@/assets/images/comm/bg.png) center bottom no-repeat;
	background-size: 80% 60%;

	.left,
	.right {
		position: absolute;
		// flex-shrink: 0;
		top: 100px;
		width: 400px;
		height: 60%;
		display: flex;
		flex-direction: column;
		padding: 0 20px;
		z-index: 10;

		:first-child {
			margin-top: 0;
		}

		>div {
			margin-top: 20px;
			flex: 1;
		}
	}

	.left {
		left: 0;

		.zonghe {
			flex-wrap: wrap;
			// justify-content: center;
			align-items: center;
			height: 100%;
			padding: 15px 0;

			>div {
				// flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: center;
				// align-items: center;
				padding-left: 40px;

				.label {
					font-size: 14px;
					white-space: nowrap;
				}

				.value {
					font-size: 20px;
					font-weight: bold;
				}
			}
		}
	}

	.right {
		right: 0;
	}

	.center {
		// flex: 1;
		width: 100%;
		height: 100%;
		position: relative;

		// top:215px;
		.goback {
			position: absolute;
			top: 285px;
			left: 1450px;
			width: 100px;
			height: 50px;
			z-index: 15;
			// border: 1px solid #1A7683;
			background: linear-gradient(180deg,
					rgba(14, 134, 139, 0.88) 0%,
					rgba(0, 43, 41, 0.6) 100%);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 4px;
			cursor: pointer;

			.location {
				width: 20px;
				height: 20px;
				margin-right: 15px;
				transform: rotate(180deg);
			}
		}
	}
}

.show2dBtn {
	background: url("~@/assets/images/index/3d_1.png");
	background-size: 100% 100%;
	display: block;
	position: fixed;
	width: 20px;
	height: 20px;
	margin-right: 40px;
	top: 20px;
	right: 60px;
	cursor: pointer;

	&.show3dBtn {
		background: url("~@/assets/images/index/2d_1.png");
		background-size: 100% 100%;
	}
}
</style>