<template>
    <div class="map-part">
      <title1 title="能源指标" subTitle="Energy Index" />
      <div class="contion">
        <div class="box">
          <divBox title="各城市单车日均充换电电量">
            <myEchart
              :visible="powerExchangeMileageShow"
              :id="'PowerExchangeMileage'"
              :options="powerExchangeMileageOptions"
            />
          </divBox>
        </div>
        <div class="box">
          <divBox title="各城市单日日均充换电次数">
            <myEchart
              :visible="powerExchangeNumberShow"
              :id="'PowerExchangeNumber'"
              :options="powerExchangeNumberOptions"
            />
          </divBox>
        </div>
        <div class="box">
          <divBox title="各城市车辆平均充换电SOC">
            <myEchart
              :visible="cheliangSOCShow"
              :id="'CheliangSOC'"
              :options="cheliangSOCOptions"
            />
          </divBox>
        </div>
        <div class="box">
          <divBox title="24h充换电次数分布">
            <myEchart
              :visible="powerChangeTimeShow"
              :id="'PowerChangeTime'"
              :options="powerChangeTimeOptions"
            />
          </divBox>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import divBox from "../common/divBox.vue";
    import myEchart from '@/views/components/charts/echarts.vue'
    import { dataInterface } from '@/api/dataInterfaceApi';
    import title1 from '@/views/exposition/components/title1'
    import * as echarts from 'echarts'
  
  export default {
    components: {
		myEchart,
		divBox,
		title1
    },
    data() {
      return {
        bigtitle: {
          CHN: "能源指标",
          ENG: "Energy indicators",
        },
        dataBase: null,
        // 各类型平均变化速度
        batteryTypeStatisticsOptions: {},
        batteryTypeStatisticsShow: false,
        batteryTypeList: [
          {
            label: "各电池类型平均变化速度",
            unit: "km/min",
            value: "quanguo_shengshi-各电池类型平均变化速度",
          },
          {
            label: "各车辆型号平均变化速度",
            unit: "km/min",
            value: "quanguo_shengshi-各车辆型号平均变化速度",
          },
          {
            label: "各城市平均变化速度",
            unit: "km/min",
            value: "quanguo_shengshi-各城市平均变化速度",
          },
        ],
        batteryTypeAt: 0,
        // SOC仿真速度统计
        cheliangTypeStatisticsOptions: {},
        cheliangTypeStatisticsShow: false,
        mileageStatisticsShow: false,
        mileageStatisticsOptions: {},
        cheliangTypeList: [
          {
            label: "仿真平均能耗",
            unit: "度/min",
            value: "quanguo_shengshi-soc仿真平均能耗",
          },
          {
            label: "仿真平均度电里程",
            unit: "km/度",
            value: "quanguo_shengshi-soc仿真平均度电里程",
          },
        ],
        cheliangTypeAt: 0,
        // 近10日告警趋势
        tenDaysEmergencyOptions: {},
        tenDaysEmergencyShow: false,
        // 各城市单车日均换电电量
        powerExchangeMileageOptions: {},
        powerExchangeMileageShow: false,
        // 各城市单车日均换电次数
        powerExchangeNumberOptions: {},
        powerExchangeNumberShow: false,
        // 各城市车辆平均换下SOC
        cheliangSOCOptions: {},
        cheliangSOCShow: false,
        // 换电次数时间分布
        powerChangeTimeOptions: {},
        powerChangeTimeShow: false,
      };
    }, 
    props: {},
    watch: {},
    mounted() {
        this.getData()
    },
    methods: {
      // 刷新接口需要每个子组件封装一个getData();
      getData(data) {

        // 各城市单车日均换电电量
        this.powerExchangeMileage(data);
        // 各城市单车日均换电次数
        this.powerExchangeNumber(data);
        // 各城市车辆平均换下SOC
        this.cheliangSOC(data);
        // 换电次数时间分布
        this.powerChangeTime(data);
      },
      // 各城市单车日均换电电量
      powerExchangeMileage(data = {}) {
        let params = {};
        let query = this.$route.query;
        params = {
          // 'enCode': 'quanguo-各城市单车日均换电里程',
          enCode: "quanguo-各城市单车日均充换电电量",
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        dataInterface.dataFaceApi(params).then((res) => {
          if (!res || !res.length) return;
          let data = res.sort(function (a, b) {
            // return b.per_vehicle_daily_total_elect - a.per_vehicle_daily_total_elect
            return (
              parseFloat(b.per_vehicle_daily_exchange_elect || 0) +
              parseFloat(b.per_vehicle_daily_charge_elect || 0) -
              (parseFloat(a.per_vehicle_daily_exchange_elect || 0) +
                parseFloat(a.per_vehicle_daily_charge_elect || 0))
            );
          });
          let xAxisData = [];
          let seriesData1 = [];
          let seriesData2 = [];
          data.map((item) => {
            xAxisData.push(item.address);
            seriesData1.push(item.per_vehicle_daily_exchange_elect || 0);
            seriesData2.push(item.per_vehicle_daily_charge_elect || 0);
          });
          this.powerExchangeMileageOptions = {
            title: {
              text: "kWh",
              top: "3%",
              left: "2%",
              textStyle: {
                color: "#b3b3c9",
              },
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {
              right: "3%",
              data: ["换电电量", "充电电量"],
            },
            // grid: {
            // 	left: '3%',
            // 	right: '4%',
            // 	bottom: '3%',
            // 	containLabel: true
            // },
            xAxis: [
              {
                type: "category",
                data: xAxisData,
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: "换电电量",
                type: "bar",
                barWidth: 20,
                // stack: 'cheliangComparison',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#0DC2F0" },
                    { offset: 1, color: "rgba(11,67,83,0.1)" },
                  ]),
                },
                data: seriesData1,
              },
              {
                name: "充电电量",
                type: "bar",
                barWidth: 20,
                // stack: 'cheliangComparison',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#16F3C8" },
                    { offset: 1, color: "rgba(22,62,56,0.1)" },
                  ]),
                },
                data: seriesData2,
              },
            ],
          };
          this.powerExchangeMileageShow = true;
        });
      },
      // 各城市单车日均换电次数
      powerExchangeNumber(data = {}) {
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: "quanguo-各城市单车日均充换电次数",
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
          "@OPER_TYPE": "充换电",
        };
        dataInterface.dataFaceApi(params).then((res) => {
          if (!res || !res.length) return;
          let data = res.sort(function (a, b) {
            return (
              parseFloat(b.per_vehicle_daily_exchange_num || 0) +
              parseFloat(b.per_vehicle_daily_charge_num || 0) -
              (parseFloat(a.per_vehicle_daily_exchange_num || 0) +
                parseFloat(a.per_vehicle_daily_charge_num || 0))
            );
          });
          let xAxisData = [];
          let seriesData1 = [];
          let seriesData2 = [];
          data.map((item) => {
            xAxisData.push(item.address);
            seriesData1.push(item.per_vehicle_daily_exchange_num || 0);
            seriesData2.push(item.per_vehicle_daily_charge_num || 0);
            // seriesData.push({
            // 	value: parseFloat(item.per_vehicle_daily_exchange_num, 10),
            // 	itemStyle: {
            // 		borderRadius: 5,
            // 	}
            // })
          });
          this.powerExchangeNumberOptions = {
            title: {
              text: "次",
              top: "3%",
              left: "2%",
              textStyle: {
                color: "#b3b3c9",
              },
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {
              right: "3%",
            },
            // grid: {
            // 	left: '3%',
            // 	right: '4%',
            // 	bottom: '3%',
            // 	containLabel: true
            // },
            xAxis: [
              {
                type: "category",
                data: xAxisData,
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: "换电次数",
                type: "bar",
                barWidth: 20,
                // stack: 'cheliangComparison',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#0DC2F0" },
                    { offset: 1, color: "rgba(11,67,83,0.1)" },
                  ]),
                },
                data: seriesData1,
              },
              {
                name: "充电次数",
                type: "bar",
                barWidth: 20,
                // stack: 'cheliangComparison',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#16F3C8" },
                    { offset: 1, color: "rgba(22,62,56,0.1)" },
                  ]),
                },
                data: seriesData2,
              },
            ],
          };
          this.powerExchangeNumberShow = true;
        });
      },
      // 各城市车辆平均换下SOC
      cheliangSOC(data = {}) {
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: "quanguo-各城市车辆平均换下SOC",
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        dataInterface.dataFaceApi(params).then((res) => {
          if (!res || !res.length) return;
          let data = res.sort(function (a, b) {
            //return b.down_soc - a.down_soc})
            return (
              parseFloat(b.exchange_down_soc || 0) +
              parseFloat(b.charge_start_soc || 0) -
              (parseFloat(a.exchange_down_soc || 0) +
                parseFloat(a.charge_start_soc || 0))
            );
          });
          let xAxisData = [];
          let seriesData1 = [];
          let seriesData2 = [];
          data.map((item) => {
            xAxisData.push(item.address);
            seriesData1.push(item.exchange_down_soc || 0);
            seriesData2.push(item.charge_start_soc || 0);
            // seriesData.push({
            // 	value: parseFloat(item.down_soc, 10),
            // 	itemStyle: {
            // 		borderRadius: 5,
            // 	}
            // })
          });
          this.cheliangSOCOptions = {
            title: {
              text: "%",
              top: "3%",
              left: "2%",
              textStyle: {
                color: "#b3b3c9",
              },
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            legend: {
              right: "3%",
            },
            // grid: {
            // 	left: '3%',
            // 	right: '4%',
            // 	bottom: '3%',
            // 	containLabel: true
            // },
            xAxis: [
              {
                type: "category",
                data: xAxisData,
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: "换下SOC",
                type: "bar",
                barWidth: 20,
                // stack: 'cheliangComparison',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#0DC2F0" },
                    { offset: 1, color: "rgba(11,67,83,0.1)" },
                  ]),
                },
                data: seriesData1,
              },
              {
                name: "开始充电SOC",
                type: "bar",
                barWidth: 20,
                // stack: 'cheliangComparison',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#16F3C8" },
                    { offset: 1, color: "rgba(22,62,56,0.1)" },
                  ]),
                },
                data: seriesData2,
              },
            ],
          };
          this.cheliangSOCShow = true;
        });
      },
      // 换电次数时间分布
      powerChangeTime(data = {}) {
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: "quanguo_shengshi-充换电次数时间分布",
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
          "@OPER_TYPE": "充换电",
        };
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res || !res.length) return;
            // 时间排序
            let data = res.sort(function (a, b) {
              return a.cur_hour - b.cur_hour;
            });
            let xAxis = [];
            let seriesData1 = [];
            let seriesData2 = [];
            data.map((item) => {
              xAxis.push(item.cur_hour);
              seriesData1.push(item.exchange_num);
              seriesData2.push(item.charge_num);
            });
            this.powerChangeTimeOptions = {
              // colors: ["#1CD3F3", "#7C6AF2"],
              title: {
                text: "次",
              },
              legend: {
                right: "3%",
                itemWidth: 10,
                itemHeight: 10,
                textStyle: {
                  color: "#fff",
                },
                data: ["充电次数", "换电次数"],
              },
              tooltip: {
                trigger: "axis",
              },
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: xAxis,
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              series: [
                {
                  name: "充电次数",
                  type: "line",
                  // stack: 'Total',
                  smooth: true,
                  lineStyle: {
                    width: 2,
                    color: "#10B8DA",
                  },
                  itemStyle: {
                    normal: {
                      color: "#10B8DA",
                    },
                  },
                  showSymbol: false,
                  // areaStyle: {
                  //   opacity: 0.8,
                  //   color: "rgba(28, 211, 243, .2)",
                  // },
                  emphasis: {
                    focus: "series",
                  },
                  data: seriesData2,
                },
                {
                  name: "换电次数",
                  type: "line",
                  // stack: 'Total',
                  smooth: true,
                  lineStyle: {
                    width: 2,
                    color: "#79EEF1",
                  },
                  itemStyle: {
                    normal: {
                      color: "#79EEF1",
                    },
                  },
                  showSymbol: false,
                  areaStyle: {
                    opacity: 0.8,
                    color: "rgba(124, 106, 242, .2)",
                  },
                  emphasis: {
                    focus: "series",
                  },
                  data: seriesData1,
                },
              ],
            };
            this.powerChangeTimeShow = true;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 各类型平均变化速度
      setBatteryTypeAt(i) {
        this.batteryTypeAt = i;
        this.batteryTypeStatistics();
      },
      // 各类型平均变化速度
      batteryTypeStatistics() {
        let data = this.dataBase || {};
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: this.batteryTypeList[this.batteryTypeAt].value, //'quanguo_shengshi-各电池类型平均能耗',
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        this.batteryTypeStatisticsShow = false;
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res || !res.length) return;
            // 排序
            let data = res.sort(function (a, b) {
              return (
                new Date(a.soc_month.replace("-", "/")) -
                new Date(b.soc_month.replace("-", "/"))
              );
            });
            // let months = []
            let monthData = {};
            let batterys = [];
            data.map((item) => {
              if (!monthData[item.soc_month]) {
                monthData[item.soc_month] = [];
              }
              monthData[item.soc_month].push(item);
              // 所有电池类型
              if (
                !batterys.includes(
                  item.battery_type || item.cat_model || item.city
                )
              ) {
                batterys.push(item.battery_type || item.cat_model || item.city);
              }
            });
            let xAxis = Object.keys(monthData);
            let seriesData = [];
            batterys.map((battery) => {
              let sData = {
                name: battery,
                type: "line",
                showSymbol: false,
                data: [],
              };
              seriesData.push(sData);
            });
            xAxis.map((item) => {
              seriesData.map((sData) => {
                let a =
                  monthData[item].find((i) => {
                    return (
                      (i.battery_type || i.cat_model || i.city) == sData.name
                    );
                  }) || {};
                sData.data.push(a.avg_change_speed || 0);
              });
            });
            this.batteryTypeStatisticsOptions = {
              color: ["#1968FF", "#3DDDFF", "#FFFA6D", "#77E8A9"],
              title: {
                text: this.batteryTypeList[this.batteryTypeAt].unit,
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross",
                  label: {
                    backgroundColor: "#6a7985",
                  },
                },
              },
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: xAxis,
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              series: seriesData,
            };
            this.batteryTypeStatisticsShow = true;
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      // SOC仿真速度统计
      setCheliangTypeAt(i) {
        this.cheliangTypeAt = i;
        this.cheliangTypeStatistics();
      },
      // SOC仿真速度统计
      cheliangTypeStatistics(type) {
        let data = this.dataBase;
        let params = {};
        let query = this.$route.query;
        let typeObj = this.cheliangTypeList[type];
        params = {
          enCode: typeObj.value, //'quanguo_shengshi-各车辆型号平均能耗',
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        //
        if (type == 0) {
          this.cheliangTypeStatisticsShow = false;
        } else {
          this.mileageStatisticsShow = false;
        }
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res || !res.length) return;
            // 排序
            let data = res.sort(function (a, b) {
              return a.soc - b.soc;
            });
            // let months = []
            let xAxis = [];
            let seriesData = {
              name: typeObj.label,
              type: "line",
              showSymbol: false,
              data: [],
            };
            data.map((item) => {
              xAxis.push(item.soc);
              seriesData.data.push(
                item.energy_consumption || item.avg_per_kwh_mileage
              );
            });
            let Options = {
              color: ["#62F4CA", "#1A6AFF", "#93E59A", "#46FFF5", "#9256AD"],
              title: {
                text: typeObj.unit,
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross",
                  label: {
                    backgroundColor: "#6a7985",
                  },
                },
              },
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: xAxis,
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              series: seriesData,
            };
  
            if (type == 0) {
              this.cheliangTypeStatisticsOptions = Options;
              this.cheliangTypeStatisticsShow = true;
            } else {
              this.mileageStatisticsOptions = Options;
              this.mileageStatisticsShow = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      // 近10日告警趋势
      tenDaysEmergency(data = {}) {
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: "quanguo_shengshi-近10天告警趋势",
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res) return;
            let data = res;
            let xAxis = [];
            let yAxis = [];
            data.map((item) => {
              xAxis.push(item.cur_date);
              yAxis.push(parseInt(item.alarm_cnt, 10));
            });
            this.tenDaysEmergencyOptions = {
              title: {
                text: "次",
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross",
                  label: {
                    backgroundColor: "#6a7985",
                  },
                },
              },
              // grid: {
              // 	left: '3%',
              // 	right: '4%',
              // 	bottom: '3%',
              // 	containLabel: true
              // },
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: xAxis,
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              series: [
                {
                  name: "告警数量",
                  type: "line",
                  stack: "Total",
                  smooth: true,
                  lineStyle: {
                    width: 2,
                    color: "#289CFF",
                  },
                  showSymbol: false,
                  areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "rgba(54, 161, 255, 1)",
                      },
                      {
                        offset: 1,
                        color: "rgba(25, 104, 255, 0)",
                      },
                    ]),
                  },
                  emphasis: {
                    focus: "series",
                  },
                  data: yAxis,
                },
              ],
            };
            this.tenDaysEmergencyShow = true;
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  // @import './part.scss';
  .contion {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .box {
    margin-top: 10px;
    width: 430px;
    height: 350px;
    margin-bottom: 140px;
  }
  </style>
  