/**
 * @param {Function} fn 防抖函数
 * @param {Number} delay 延迟时间
 */
// export function debounce(fn, delay) {
//   var timer;
//   return function () {
//     var context = this;
//     var args = arguments;
//     clearTimeout(timer);
//     timer = setTimeout(function () {
//       fn.apply(context, args);
//     }, delay);
//   };
// }
export function debounce(func, delay, immediate = false) {
  let timer, context = this
  return (...args) => {
    if (immediate) {
      func.apply(context, args)
      immediate = false
      return
    }
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(context, args)
    }, delay)
  }
}

/**
 * @param {date} time 需要转换的时间
 * @param {String} fmt 需要转换的格式 如 yyyy-MM-dd、yyyy-MM-dd HH:mm:ss
 */
export function formatTime(time, fmt) {
  if (!time) return '';
  else {
    const date = new Date(time);
    const o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'H+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds(),
      'q+': Math.floor((date.getMonth() + 3) / 3),
      S: date.getMilliseconds(),
    };
    if (/(y+)/.test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        (date.getFullYear() + '').substr(4 - RegExp.$1.length)
      );
    for (const k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        );
      }
    }
    return fmt;
  }
}


// num为设置的大小（px）
export function  getSizeByScreen(num) { 
  // 这里的3200为你设计稿的宽度。 5760
  return Math.ceil((num / 7000) * window.screen.width);
}

//判断是否存在
export function changeToVoidString(data){
  return data||"";
}

//格式化数据
export function formatter(name){
  console.log(name);
  switch (name) {
    case "内蒙古自治区":
      name = "内蒙古";
      break;
    case "西藏自治区":
      name = "西藏";
      break;
    case "新疆维吾尔自治区":
      name = "新疆";
      break;
    case "宁夏回族自治区":
      name = "宁夏";
      break;
    case "广西壮族自治区":
      name = "广西";
      break;
    case "香港特别行政区":
      name = "香港";
      break;
    case "澳门特别行政区":
      name = "澳门";
      break;
    default:
      break;
  }
  return name;
}

// 10000转1W
export function numChange(num){
  if(num>=10000){
    num=Math.round(num/1000)/10+'W';
  }
  return num;
}
// 格式化数字 k，w
export function formatNumber(num) {
  return num >= 1e3 && num < 1e4 ? (num / 1e3).toFixed(1) + 'k' : num >= 1e4 ? (num / 1e4).toFixed(1) + 'w' : num
}
