<template>
    <div class="dv-box-1">
        <!-- <span class="angle left-up"></span>
        <span class="angle right-up"></span>
        <span class="angle left-down"></span>
        <span class="angle right-down"></span> -->
        <div v-if="title" class="dv-title" style="textColor">
          <img src="@/assets/images/index/Titledecoration.png" alt="">
          {{ title }}
            <div class="decorate"></div>
            <!-- <dv-decoration-10 style="width:90%;height:5px;" /> -->
        </div>
        <div class="dv-box-con">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        textColor: {
            type: String,
            default: 'color:#3EFFF4 !important;'
        }
    }
}
</script>

<style lang="scss" scoped>
.dv-box-1 {
    width: 100%;
    height: 100%;
    // padding: 10px 20px 40px 20px;
    position: relative;
    // background: linear-gradient(to bottom, #0B1114, #131D23);
    // background: linear-gradient(360deg, rgba(19,29,35, .4) 0%, rgba(11, 17, 20, .4));
    display: flex;
    flex-direction: column;
    .angle {
        width: 20px;
        height: 20px;
        z-index: 1;
        position: absolute;
        &.left-up {
            top: 0;
            left: 0;
            border-left: 2px solid #2BA39E;
            border-top: 2px solid #2BA39E
        }
        &.left-down {
            bottom: 0;
            left: 0;
            border-left: 2px solid #2BA39E;
            border-bottom: 2px solid #2BA39E;
        }
        &.right-up {
            top: 0;
            right: 0;
            border-right: 2px solid #2BA39E;
            border-top: 2px solid #2BA39E;
        }
        &.right-down {
            bottom: 0;
            right: 0;
            border-right: 2px solid #2BA39E;
            border-bottom: 2px solid #2BA39E;
        }
    }
    .dv-title {
        // color: #3EFFF4;
        font-size: 20px;
        // text-align: center;
        height: 40px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        position: relative;
        margin: 0 20px;
        .decorate {
            position: absolute;
            bottom: 0;
            width: 100%;
            // background: url(~@/assets/images/energyMuseum/line.png) no-repeat;
            background-size: 100% 100%;
            height: 2px;
            &::before, &::after {
                position: absolute;
                content: '';
                width: 5px;
                height: 5px;
                // background: url(~@/assets/images/energyMuseum/plus.png) no-repeat;
                background-size: 100% 100%;
                top: 50%;
                transform: translate(0, -50%);
            }
            &::before {
                left: 0;
            }
            &::after {
                right: 0;
            }
        }
    }
    .dv-box-con {
        width:100%;
        height:100%;

    }
}
</style>
