<template>
  <div class="target flex justify-between">
    <div class="postion" v-for="(item, index) in targetList" :key="index">
      <!-- <img class="pos-line" src="@/assets/images/index/line.png" />
			<img class="pos-logo" src="@/assets/images/index/evaluation_logo.png" /> -->
      <div style="flex: 1;" class="back">
        <p class="text">{{ item.text }}</p>
        <div class="counts">
          <div v-if="item.counts" class="item_counts">{{ item.counts }}</div>
          <div v-else>&nbsp;&nbsp;</div>
          <div class="item_text" v-if="item.counts">
            <span>&nbsp;&nbsp;{{ item.unit }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="icon">
				<img
					:src="item.icon"
					:style="{ width: item.width, height: item.height }" />
			</div> -->
    </div>
  </div>
</template>
<script>
import { dataInterface } from "@/api/dataInterfaceApi";
import vehicleAll from "@/assets/images/comm/vehicleAll.png";
import carbonEmissionsAll from "@/assets/images/comm/carbonEmissionsAll.png";
import mileageAll from "@/assets/images/comm/mileageAll.png";
import electricityAll from "@/assets/images/comm/electricityAll.png";
export default {
  data() {
    return {
      targetList: [
        {
          counts: "",
          text: "车辆总数",
          icon: vehicleAll,
          width: "90px",
          unit: "辆",
        },
        {
          counts: "",
          text: "碳减排",
          icon: carbonEmissionsAll,
          width: "90px",
          unit: "kg",
        },
        {
          counts: "",
          text: "总行驶里程",
          icon: mileageAll,
          height: "70px",
          unit: "km",
        },
        {
          counts: "",
          text: "总放电量",
          icon: electricityAll,
          height: "70px",
          unit: "kWh",
        },
      ],
    };
  },
  components: {},
  computed: {},
  props: {},
  watch: {},
  mounted() {
    this.getData();
  },
  methods: {
    // 初始化
    getData(data) {
      console.log("adfadfadsf", data);
      // 车辆总数
      this.vehicleAll(data);
      // 碳减排量
      this.carbonEmissionsAll(data);
      // 总里程
      this.mileageAll(data);
      // 总电量
      this.electricityAll(data);
    },
    // 车辆总数
    vehicleAll(data = {}) {
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: "quanguo_shengshi-车辆总数",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          let data = (res && res[0]) || {};
          this.targetList[0].counts = parseInt(data.total_vehicle_num, 10);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 碳减排量
    carbonEmissionsAll(data = {}) {
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: "quanguo_shengshi-碳减排量",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          let data = (res && res[0]) || {};
          // this.targetList[1].counts = data.carbon_emissions_cc.slice(0, -4) + 'w'
          this.targetList[1].counts = data.carbon_emissions_cc;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 总里程
    mileageAll(data = {}) {
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: "quanguo_shengshi-总里程",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          let data = (res && res[0]) || {};
          this.targetList[2].counts = parseInt(
            data.total_consumption_mileage,
            10
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 总电量
    electricityAll(data = {}) {
      let params = {};
      let query = this.$route.query;
      params = {
        enCode: "quanguo_shengshi-总电量",
        "@VEHICLE_TYPE":
          query.vehicle == "1"
            ? "乘用车"
            : query.vehicle == "2"
            ? "商用车"
            : "全部",
        "@ADCODE":
          (data ? data.province || data.city || data.adcode : "") || "000000",
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          let data = (res && res[0]) || {};
          this.targetList[3].counts = parseInt(
            data.total_consumption_elect,
            10
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.justify-between {
  justify-content: space-between;
}
// .back{
//   background-size:100% 100%;
// }

.postion:nth-child(1) {
  background: url("~@/assets/images/index/co1.png") no-repeat;
  background-size: 100% auto;
  margin-left: 100px;
}
.postion:nth-child(2) {
  background: url("~@/assets/images/index/co2.png") no-repeat;
  background-size: 100% auto;
  margin-left: -350px;
}
.postion:nth-child(3) {
  background: url("~@/assets/images/index/co3.png") no-repeat;
  background-size: 100% auto;
  margin-right: -350px;
}
.postion:nth-child(4) {
  background: url("~@/assets/images/index/co4.png") no-repeat;
  background-size: 100% auto;
  margin-right: 100px;
}
.target {
  width: 100%;
  height: 122px;
  display: flex;
  margin-top: 20px;
  // margin-bottom:30px;

  .postion {
    position: static;
    z-index: 100;
    // margin: 0 20px;
    // flex: 1;
    width: 240px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
    // background: linear-gradient(180deg, rgba(47, 164, 159, 0.11) 0%, rgba(1, 177, 169, 0.06) 79%, rgba(53, 149, 147, 0.11) 100%);
    .pos-line {
      width: 6px;
      height: 62px;
    }
    .pos-logo {
      width: 32px;
      height: 32px;
      margin: 0 30px 0 15px;
    }
  }
  .icon {
    width: 100px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .counts {
    font-size: 30px;
    // margin-left: 45px;
    // margin-top:5px;
    margin-left: 22px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffc166;
    .item_text {
      font-size: 14px;
      color: #fff;
    }
    .item_counts {
      font-family: "DS-Digital-Bold";
    }
  }
  .text {
    margin-left: 60px;
    font-size: 22px;
    line-height: 18px;
    margin-top: -28px;
    // height:85px;
  }
}
</style>
