<!--  -->
<template>
	<div class="map-2d" style="position: relative;">
		<component :is="currenIndex" v-cloak />
	</div>
</template>

<script>
// import { dataInterface } from '@/api/dataInterfaceApi'
import sctterMap from './sctterMap'
// import dotMap from './dotMap'
// import heatMap from './heatMap'
// import allMap from "./allMap"
// import weixingMap from './weixinMap'

export default {
	name: 'map2D',
	components: {
		sctterMap,
		// dotMap,
		// heatMap,
		// allMap,
		// weixingMap
	},
	data() {
		return {
			currenIndex: 'sctterMap',
			// currenIndex: 'weixingMap',
		}
	},
	mounted() {},
	methods: {
		changeList(val) {
			if (val.type == this.currenIndex) {
				return
			}
			this.currenIndex = val.type
		},
	},
}
</script>
<style lang="scss" scoped>
.map-2d {
	width: 100;
	height: 100%;
	position: relative;

	.select {
		position: absolute;
		left: 1%;
		top: 15%;
		z-index: 15;

		.select_item {
			padding: 6px 25px;
			color: rgb(179, 239, 255);
			background: rgba(147, 235, 248, .35);
			border-radius: 15px;
			margin: 25px 0;
			cursor: pointer;

			.active {
				color: rgb(254, 217, 50);
			}
		}
	}

	.home_top {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		top: 0;
		z-index: 15;
		font-size: 32px;
	}

	.home_number {
		width: 70px;
		height: 76px;
		line-height: 76px;
		font-size: 60px;
		font-family: 'DS-Digital-Bold';
		color: #00FDF4;
		text-align: center;
		background-image: linear-gradient(45deg, rgba(35, 94, 92, 0.00) 0%, rgba(69, 196, 190, 0.60) 48%, rgba(36, 98, 96, 0.03) 100%, rgba(35, 94, 92, 0.00) 100%);
		border-radius: 6px;
		border-radius: 6px;
	}
}



</style>
