<template>
    <div class="map-part mt-20">
      <title1 title="双碳类型指标" subTitle="Double Carbon Index" />
      <div class="contion mt-20">
        <div class="box">
          <divBox title="各类型能耗统计">
            <div class="flex SOC-type">
              <span
                v-for="(item, i) in energyType"
                :key="'energyType' + i"
                :class="{ at: i == energyTypeAt }"
                @click="setEnergyTypeAt(i)"
              >
                {{ item.label }}
              </span>
            </div>
            <myEchart
              class="myEchart"
              :visible="energySimulationShow"
              :id="'energySimulation'"
              :options="energySimulationOptions"
            />
          </divBox>
        </div>
        <div class="box">
          <divBox title="SOC、平均能耗与度电里程">
            <myEchart
              :visible="mileageAndSOCShow"
              :id="'MileageAndSOC'"
              :options="mileageAndSOCOptions"
            />
          </divBox>
        </div>
      </div>
    </div>
  </template>
  
  <script>
    import divBox from "../common/divBox.vue";
    import myEchart from '@/views/components/charts/echarts.vue'
    import { dataInterface } from "@/api/dataInterfaceApi";
    import title1 from '@/views/exposition/components/title1'
    import * as echarts from "echarts";
  
  export default {
    components: {
      divBox,
      myEchart,
      title1
    },
    data() {
      return {
        bigtitle1: {
          CHN: "双碳类型指标",
          ENG: "Traffic indicators",
        },
        // 车辆车型
        cheliangModelOptions: {},
        cheliangModelShow: false,
        // 车辆入网趋势
        cheliangNetworkAccessOptions: {},
        cheliangNetworkAccessShow: false,
        // SOC、平均能耗与度电里程
        mileageAndSOCOptions: {},
        mileageAndSOCShow: false,
        divflex: "none",
        energyTypeAt: 0,
        energyType: [
          {
            label: "各车辆型号平均能耗",
            unit: "度/min",
            value: "quanguo_shengshi-各车辆型号平均能耗",
          },
          {
            label: "各电池类型平均能耗",
            unit: "度/min",
            value: "quanguo_shengshi-各电池类型平均能耗",
          },
        ],
        // 各类型能耗统计
        energySimulationOptions: {},
        energySimulationShow: false,
      };
    },
    props: {},
    watch: {},
    mounted() {
        this.getData()
    },
    methods: {
      // 刷新接口需要每个子组件封装一个getData();
      getData(data) {
        this.dataBase = data;
        // 各类型能耗统计
        this.energySimulation(data);
        // SOC、平均能耗与度电里程
        this.mileageAndSOC(data);
      },
  
      // 各类型能耗统计
      setEnergyTypeAt(i) {
        this.energyTypeAt = i;
        this.energySimulation();
      },
      energySimulation() {
        let data = this.dataBase;
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: this.energyType[this.energyTypeAt].value,
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        this.energySimulationShow = false;
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res || !res.length) return;
            // 排序
            let data = res.sort(function (a, b) {
              return (
                new Date(a.soc_month.replace("-", "/")) -
                new Date(b.soc_month.replace("-", "/"))
              );
            });
            // let months = []
            let monthData = {};
            let batterys = [];
            data.map((item) => {
              if (!monthData[item.soc_month]) {
                monthData[item.soc_month] = [];
              }
              monthData[item.soc_month].push(item);
              // 所有电池类型
              if (!batterys.includes(item.battery_type || item.cat_model)) {
                batterys.push(item.battery_type || item.cat_model);
              }
            });
            let xAxis = Object.keys(monthData);
            let seriesData = [];
            let colors = ["#62F4CA", "#1A6AFF", "#9256AD", "#5B8FF9", "#93E59A", "#46FFF5", "#91E679", "#F5EF50", "#FFA63F", "#34CE84", "#FFFA6D", "#77E8A9"]
            batterys.map((battery) => {
              let sData = {
                name: battery,
                type: "line",
                showSymbol: false,
                data: [],
              };
              seriesData.push(sData);
            });
            xAxis.map((item) => {
              seriesData.map((sData, i) => {
                let a =
                  monthData[item].find((i) => {
                    return (i.battery_type || i.cat_model) == sData.name;
                  }) || {};
                sData.data.push(a.avg_energy_consumption || 0);
                sData.lineStyle = {
                  color: colors[i]
                }
              });
            });
            this.energySimulationOptions = {
              title: {
                text: this.energyType[this.energyTypeAt].unit,
              },
              // tooltip: {
              //   trigger: "",
              //   axisPointer: {
              //     type: "cross",
              //     label: {
              //       backgroundColor: "#6a7985",
              //     },
              //   },
              // },
              legend: { hidden: true },
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: xAxis,
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              series: seriesData,
            };
            this.energySimulationShow = true;
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      // 各类型度电里程统计
      setMileageTypeAt(i) {
        this.mileageTypeAt = i;
        this.mileageSimulation();
      },
      mileageSimulation() {
        let data = this.dataBase;
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: this.mileageType[this.mileageTypeAt].value,
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        this.mileageShow = false;
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res || !res.length) return;
            // 排序
            let data = res.sort(function (a, b) {
              return (
                new Date(a.soc_month.replace("-", "/")) -
                new Date(b.soc_month.replace("-", "/"))
              );
            });
            // let months = []
            let monthData = {};
            let batterys = [];
            data.map((item) => {
              if (!monthData[item.soc_month]) {
                monthData[item.soc_month] = [];
              }
              monthData[item.soc_month].push(item);
              // 所有电池类型
              if (!batterys.includes(item.battery_type || item.cat_model)) {
                batterys.push(item.battery_type || item.cat_model);
              }
            });
            let xAxis = Object.keys(monthData);
            let seriesData = [];
            batterys.map((battery) => {
              let sData = {
                name: battery,
                type: "line",
                showSymbol: false,
                data: [],
              };
              seriesData.push(sData);
            });
            xAxis.map((item) => {
              seriesData.map((sData) => {
                let a =
                  monthData[item].find((i) => {
                    return (i.battery_type || i.cat_model) == sData.name;
                  }) || {};
                sData.data.push(a.avg_mileage_per_kwh || 0);
              });
            });
            this.mileageOptions = {
              color: ["#1968FF", "#3DDDFF", "#FFFA6D", "#77E8A9"],
              title: {
                text: this.mileageType[this.mileageTypeAt].unit,
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross",
                  label: {
                    backgroundColor: "#6a7985",
                  },
                },
              },
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: xAxis,
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              series: seriesData,
            };
            this.mileageShow = true;
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      // SOC仿真能耗统计
      setSOCTypeAt(i) {
        this.SOCTypeAt = i;
        this.SOCSimulation();
      },
      SOCSimulation() {
        let data = this.dataBase;
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: this.SOCType[this.SOCTypeAt].value,
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        this.SOCSimulationShow = false;
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res || !res.length) return;
            let data = res.sort(function (a, b) {
              return a.soc - b.soc;
            });
            let xAxis = [];
            let yAxis = [];
            data.map((item) => {
              xAxis.push(item.soc);
              yAxis.push(
                parseFloat(
                  item.energy_consumption ||
                    item.avg_change_time ||
                    item.avg_per_kwh_mileage
                )
              );
            });
            this.SOCSimulationOptions = {
              title: {
                text: this.SOCType[this.SOCTypeAt].unit,
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross",
                  label: {
                    backgroundColor: "#6a7985",
                  },
                },
              },
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: xAxis,
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              series: [
                {
                  name: this.SOCType[this.SOCTypeAt].label,
                  type: "line",
                  // stack: 'Total',
                  smooth: true,
                  showSymbol: false,
                  lineStyle: {
                    width: 2,
                    color: "#5FF8FF",
                  },
                  emphasis: {
                    focus: "series",
                  },
                  data: yAxis,
                },
              ],
            };
            this.SOCSimulationShow = true;
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      // SOC、平均能耗与度电里程
      mileageAndSOC(data = {}) {
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: "quanguo_shengshi-soc仿真平均能耗和度电里程的关系",
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res || !res.length) return;
            let data = res.sort(function (a, b) {
              return a.soc - b.soc;
            });
            data = [
              res.map((item) => {
                return [item.soc, item.mileage_per_kwh, item.energy_consumption];
              }),
            ];
            this.mileageAndSOCOptions = {
              backgroundColor:'rgba(0, 0, 0, 0.1)',
              tooltip: {
                position: "top",
                formatter: function (params) {
                  params = params[0] || {};
                  return (
                    "SOC：" +
                    params.value[0] +
                    "<br>" +
                    "平均能耗：" +
                    params.value[1] +
                    "度/min<br>" +
                    "度电里程：" +
                    params.value[2] +
                    "km/度<br>"
                  );
                },
                // trigger: 'axis',
                axisPointer: {
                  type: "cross",
                },
              },
              xAxis: {
                splitLine: {
                  lineStyle: {
                    type: "dashed",
                  },
                },
               
              },
              yAxis: {
                splitLine: {
                  lineStyle: {
                    type: "dashed",
                  },
                },
                scale: true,
              },
              series: [
                {
                  name: "平均能耗",
                  data: data[0],
                  type: "scatter",
                  symbolSize: function (data) {
                    return Math.sqrt(data[2]) * 35;
                  },
                  emphasis: {
                    focus: "series",
                    label: {
                      show: true,
                      formatter: function (param) {
                        return param.data[3];
                      },
                      position: "top",
                    },
                  },
                  itemStyle: {
                    shadowBlur: 10,
                    shadowColor: "rgba(120, 36, 50, 0.5)",
                    shadowOffsetY: 5,
                    color: new echarts.graphic.RadialGradient(0.4, 0.3, 1, [
                      {
                        offset: 0,
                        color: "#3865F7",
                      },
                      {
                        offset: 1,
                        color: "#3865F7",
                      },
                    ]),
                  },
                },
              ],
            };
            this.mileageAndSOCShow = true;
          })
          .catch((err) => {
            console.log(err);
          });
      },
  
      // 速度仿真变化能耗 / 速度仿真平均度电里程
      speedMileageSimulation(type) {
        let data = this.dataBase;
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: this.consumptionType[type].value,
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        if (type === 0) {
          this.speedConsumptionShow = false;
        } else {
          this.mileageConsumptionShow = false;
        }
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res || !res.length) return;
            let data = res.sort(function (a, b) {
              return a.speed - b.speed;
            });
            let xAxis = [];
            let yAxis = [];
            data.map((item) => {
              xAxis.push(item.speed);
              yAxis.push(
                parseFloat(item.energy_consumption || item.avg_per_kwh_mileage)
              );
            });
            let options = {
              title: {
                text: this.consumptionType[type].unit,
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross",
                },
              },
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: xAxis,
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              series: [
                {
                  name: this.consumptionType[type].label,
                  type: "line",
                  smooth: true,
                  showSymbol: false,
                  lineStyle: {
                    width: 2,
                    color: "#1968FF",
                  },
                  emphasis: {
                    focus: "series",
                  },
                  data: yAxis,
                },
              ],
            };
            if (type == 0) {
              this.speedConsumptionOptions = options;
              this.speedConsumptionShow = true;
            } else {
              this.mileageConsumptionOptions = options;
              this.mileageConsumptionShow = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 车辆入网趋势
      cheliangNetworkAccess(data = {}) {
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: "quanguo_shengshi-车辆新增入网趋势",
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        dataInterface
          .dataFaceApi(params)
          .then((res) => {
            if (!res) return;
            let data = res;
            let xAxis = [];
            let yAxis = [];
            data.map((item) => {
              xAxis.push(item.cur_month);
              yAxis.push(item.new_net_vehicle_number);
            });
            this.cheliangNetworkAccessOptions = {
              title: {
                text: "辆",
                top: "3%",
                left: "2%",
                textStyle: {
                  color: "#b3b3c9",
                },
              },
              tooltip: {
                trigger: "axis",
                axisPointer: {
                  type: "cross",
                  label: {
                    backgroundColor: "#6a7985",
                  },
                },
              },
              // grid: {
              // 	left: '3%',
              // 	right: '4%',
              // 	bottom: '3%',
              // 	containLabel: true
              // },
              xAxis: [
                {
                  type: "category",
                  boundaryGap: false,
                  data: xAxis,
                },
              ],
              yAxis: [
                {
                  type: "value",
                },
              ],
              series: [
                {
                  name: "入网车辆",
                  type: "line",
                  stack: "Total",
                  smooth: true,
                  lineStyle: {
                    width: 2,
                    color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                      {
                        offset: 0,
                        color: "RGBA(61, 161, 255, 1)",
                      },
                      {
                        offset: 1,
                        color: "RGBA(52, 41, 240, 1)",
                      },
                    ]),
                  },
                  areaStyle: {
                    opacity: 0.8,
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      {
                        offset: 0,
                        color: "rgba(101, 97, 240, 0.42)",
                      },
                      {
                        offset: 1,
                        color: "rgba(118, 206, 255, 0)",
                      },
                    ]),
                  },
                  emphasis: {
                    focus: "series",
                  },
                  data: yAxis,
                },
              ],
            };
            this.cheliangNetworkAccessShow = true;
          })
          .catch((err) => {
            console.log(err);
          });
      },
      // 车辆车型
      cheliangModel(data = {}) {
        let params = {};
        let query = this.$route.query;
        params = {
          enCode: "quanguo_shengshi-车辆车型",
          "@VEHICLE_TYPE":
            query.vehicle == "1"
              ? "乘用车"
              : query.vehicle == "2"
              ? "商用车"
              : "全部",
          "@ADCODE":
            (data ? data.province || data.city || data.adcode : "") || "000000",
        };
        dataInterface.dataFaceApi(params).then((res) => {
          if (!res || !res.length) return;
          let data = res.sort(function (a, b) {
            return b.vehicle_num - a.vehicle_num;
          });
          let xAxisData = [];
          let seriesData = [];
          data.map((item) => {
            xAxisData.push(item.vehicle_model),
              seriesData.push({
                value: parseInt(item.vehicle_num, 10),
                // itemStyle: {
                // 	borderRadius: 5,
                // }
              });
          });
          this.cheliangModelOptions = {
            title: {
              text: "辆",
              top: "3%",
              left: "2%",
              textStyle: {
                color: "#b3b3c9",
              },
            },
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "shadow",
              },
            },
            // grid: {
            // 	left: '3%',
            // 	right: '4%',
            // 	bottom: '3%',
            // 	containLabel: true
            // },
            xAxis: [
              {
                type: "category",
                data: xAxisData,
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            series: [
              {
                name: "数量",
                type: "bar",
                barWidth: 20,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    { offset: 0, color: "#60F5FF" },
                    { offset: 1, color: "#3FA4FF" },
                  ]),
                  // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  // 	{ offset: 0, color: '#83bff6' },
                  // 	{ offset: 0.5, color: '#188df0' },
                  // 	{ offset: 1, color: '#188df0' }
                  // ])
                },
                emphasis: {
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                      { offset: 0, color: "#2378f7" },
                      { offset: 0.7, color: "#2378f7" },
                      { offset: 1, color: "#83bff6" },
                    ]),
                  },
                },
                data: seriesData,
              },
            ],
          };
          this.cheliangModelShow = true;
        });
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
//   @import "./part.scss";
//   @import '../scss/chd-com.scss';
  .map-part{
    width:100%;
    height:100%;
  }
  .contion {
    width:100%;
    height:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    .box {
      margin-top:10px;
      width: 430px;
      height: 350px;
      margin-bottom: 140px;
    }
  }
  </style>
  