<template>
  <div class="container">
      <div class="top">
        <part3 />
      </div>
      <div class="bottom">
        <part4/>
      </div>
  </div>
</template>

<script>
  import part3 from './components/vehiclesPart/part3.vue'
  import part4 from './components/vehiclesPart/part4.vue'

export default {
  data () {
    return {
      options: {},
    };
  },
  components: {
    part3,
    part4
  },
  props: {
    cdata: {
      type: Object,
      default: () => ({})
    },
  },
  watch: {
    
  },
  mounted () {
    
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  .top{
    width:100%;
    height:96.5%;
    display: flex;
    flex-direction: row;
    margin-bottom:50px;
  }
  .bottom{
    width: 100%;
    height: 100%;
    display: flex;
  }
}
</style>