<template>
  <div class="echarts" style="position: relative"
  :style="{'width': isChangingElectricity ? '100% !important' : '90%',
  'height': isChangingElectricity ? '100% !important' : '980px !important'}">
    <div style="width:100%;height:100%" ref="districtMap"></div>
  </div>
</template>

<script>
import echarts from 'echarts';
import resize from './mixins/resize';
import { dataInterface } from '@/api/dataInterfaceApi';
import area_diamond from '@/assets/images/index/area_diamond.png';
import area_diamond2 from '@/assets/images/index/area_diamond2.png';
import car_bg from '@/assets/images/index/car_bg.png';
import car_chongdian from '@/assets/images/index/car_chongdian.jpg';

export default {
  name: 'districtMap',
  mixins: [resize],
  data() {
    let whiteList = ['changingElectricity', 'changingElectricityMerge', 'changingElectricityProvince', 'changingElectricityCity', 'changingElectricityDistrict']
    return {
      isChangingElectricity: whiteList.includes(this.$route.name),
      myCharts: null,
      isCity: false,
      geoJson: {
        features: [],
      },
      parentInfo: [
        {
          cityName: '全国',
          code: 100000,
        },
      ],
      provinceStationInfo: [], //省站点信息
      featureCollection: {}, //super-feature
    };
  },
  components: {
    // SuperMap,
  },
  props: {
    adcode: {
      type: String,
      default: () => (""),
    },
  },
  watch: {
    adcode: {
      handler(newData) {
        if (newData) {
          console.log('newData', newData);
          if (this.$route.query && this.$route.query.city) {
            this.selectDistrictStationInfo(this.$route.query.city, newData);
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created () {
    window.popClick = this.popClick;
  },
  mounted() {
    // if (this.$route.query && this.$route.query.city) {
    //   let curAdcode = '421002';
    //   this.selectDistrictStationInfo(this.$route.query.city, curAdcode);
    // }
  },
  methods: {
    popClick(curStationId){
      console.log(curStationId)
      if(this.isChangingElectricity) {
        this.$router.push({
          path: '/powerStation',
          query: {
            stationId: curStationId
          }
        })
        return
      }
      //query-路由传参
      this.$router.push({
        path: '/pcsingleSite',
        query: {
          stationId: curStationId
        }
      })
    },
    getGeoJson(adcode, curAdcode, stationList) {
      // type： province||city
      let that = this;
      /* eslint-disable */
      AMapUI.loadUI(['geo/DistrictExplorer'], (DistrictExplorer) => {
        var districtExplorer = new DistrictExplorer();
        districtExplorer.loadAreaNode(adcode, function(error, areaNode) {
          if (error) {
            console.error(error);
            return;
          }
          let Json = areaNode.getSubFeatures();
          if (Json.length > 0) {
            that.geoJson.features = Json;
          } else if (Json.length === 0) {
            that.geoJson.features = that.geoJson.features.filter(
              (item) => item.properties.adcode == adcode
            );
            if (that.geoJson.features.length === 0) return;
          }
          that.getMapData(curAdcode, stationList);
        });
      });
    },
    async getMapData(curAdcode, stationInfo) {
      let _this = this;
      console.log('stationInfo', stationInfo);
      let mapData = stationInfo.map((item) => {
        //可以改造数据
        return {
          ...item,
          //数据value 需要改
          name: item.stationName,
          cityCode: curAdcode,
          position: [parseFloat(item.longitude), parseFloat(item.latitude)],
        };
      });

      console.log('mapData', mapData);

      this.initEcharts(mapData);
    },
    initEcharts(mapData, type) {
      var min = mapData[mapData.length - 1].value;
      var max = mapData[0].value;
      if (mapData.length === 1) {
        min = 0;
      }
      this.myChart = echarts.init(this.$refs.districtMap);
      echarts.registerMap('Map', this.geoJson); //注册
      this.myChart.setOption(
        {
          title: {
            show: false, //隐藏标题
            left: 'center',
            top: '15',
            text:
              this.parentInfo[this.parentInfo.length - 1].cityName +
              '地图(可点击下钻)',
            textStyle: {
              color: 'rgb(179, 239, 255)',
              fontSize: 16,
              // fontSize: getSizeByScreen(16),
            },
          },
          visualMap: {
            show: false,
            min: min,
            max: max,
            left: '3%',
            bottom: '5%',
            calculable: true,
            seriesIndex: [0],
            inRange: {
              //修改地图颜色
              color: ['#022A44'],
            },
            textStyle: {
              color: '#24CFF4',
            },
          },
          //提示框组件(可以设置在多种地方)
          tooltip: {
            show: true, //是否显示提示框组件，包括提示框浮层和 axisPointer。
            trigger: 'item', //触发类型。item,axis,none
            enterable: true, //鼠标是否可进入提示框浮层中，默认为false，
            showContent: true, //是否显示提示框浮层
            triggerOn: 'mousemove', //提示框触发的条件(mousemove|click|none)
            showDelay: 0, //浮层显示的延迟，单位为 ms，默认没有延迟，也不建议设置。在 triggerOn 为 'mousemove' 时有效。
            textStyle: {
              color: 'white',
              fontSize: 12,
            },
            padding: [0, 8],
            position: 'top',
            hideDelay: 10, //浮层隐藏的延迟
            formatter: (o) => {
              let statusText = '';
              let statusBg = '';
              let centerHtml = '';
              let innerHtml = '';
              if (o.componentType == 'markPoint') {
                //   此处  加判断是充电站还是换电站
                console.log('ooo', o);
                let item = o.data;
                innerHtml = `
                <div class="marker">
                  <div class="pop-content flex flex-direction" onclick="popClick('${
                    item.id
                  }')">
                    <div class="text-xsss text-left">${item.stationName}</div>
                    <div class="pop-img flex align-center justify-center"><img src="${item.station_type === '换电站' ? car_bg : car_chongdian}"/></div>
                    <div class="text-xsss text-center">可换${item.houseTotalBatteries ||
                      0}，排队${item.lineUpNum || 0}</div>
                  </div>
                  <div class="diamand">
                    <img class="diamand1" src="${area_diamond}"/>
                    <img class="diamand2" src="${area_diamond2}"/>
                  </div>
                </div>
                `;
              }
              return innerHtml;
            },
            backgroundColor: 'transparent', //提示框浮层的背景颜色。
            // borderColor: "white",  //图形的描边颜色
            // borderWidth: 2,
            alwaysShowContent: true,
            transitionDuration: 1, //提示框浮层的移动动画过渡时间，单位是 s，设置为 0 的时候会紧跟着鼠标移动。
          },
          series: [
            {
              name: '地图',
              type: 'map',
              map: 'Map',
              roam: true, //是否可缩放
              zoom: this.isChangingElectricity ? 1 : (type == 'province' || type == 'city' ? 0.9 : 1.8), //缩放比例
              data: mapData,
              top: type == 'province' || type == 'city' ? 100 : 100,
              // showAllSymbol: true,
              // symbolSize: 20,
              // symbol:"circle",
              label: {
                normal: {
                  show: true,
                  color: '#fff', //省份标签字体颜色
                  formatter: (p) => {
                    return p.value > 0 ? '' : this.formatter(p.name);
                  },
                },
                emphasis: {
                  show: false,
                  color: '#fff', //高亮之后字体颜色
                },
              },
              itemStyle: {
                normal: {
                  areaColor: '#022A44', //
                  borderColor: '#3DB2C2', //地图外边距
                  borderWidth: 1.3,
                  shadowColor: 'rgb(58,115,192)',
                  shadowOffsetX: 5,
                  shadowOffsetY: 3,
                  shadowBlur: 65,
                },
                emphasis: {
                  areaColor: '#47d8fa', //高亮之后区域颜色
                  borderWidth: 1.6,
                  shadowBlur: 25,
                },
              },
              markPoint: {
                silent: false,
                animation: 'BMAP_ANIMATION_BOUNCE',
                symbolSize: [40, 51],
                itemStyle: {
                  opacity: 1,
                },
                // symbol:
                //   'image://' + require('@/assets/images/index/symbol_icon.png'),
                  symbol: (value,params)=> {
                    // 获取当前数据是充电站还是换电站
                    const item = params.data.isCharge;
                    const imgUrl = params.data.station_type === '换电站' ? require('@/assets/images/index/symbol_icon.png') : require('@/assets/images/index/chongdian_icon.png')
                    return 'image://' + imgUrl;
                  },
                label: {
                  show: true,
                  position: 'bottom',
                  distance: 3,
                  color: '#fff',
                  fontSize: 14,
                  // fontSize: getSizeByScreen(14),
                  formatter: '{b}',
                },
                data: mapData.map((item) => {
                  const opt = {
                    ...item,
                    name: this.formatter(item.name),
                    coord: item.position,
                    value: item.value,
                    position: item.position,
                    cityCode: item.cityCode,
                    level: item.level, //省 province ，市city ，区district
                  };
                  return opt;
                }),
              },
            },
          ],
        },
        true
      );
    },

    //格式化数据
    formatter(name) {
      switch (name) {
        case '内蒙古自治区':
          name = '内蒙古';
          break;
        case '西藏自治区':
          name = '西藏';
          break;
        case '新疆维吾尔自治区':
          name = '新疆';
          break;
        case '宁夏回族自治区':
          name = '宁夏';
          break;
        case '广西壮族自治区':
          name = '广西';
          break;
        case '香港特别行政区':
          name = '香港';
          break;
        case '澳门特别行政区':
          name = '澳门';
          break;
        default:
          break;
      }
      return name;
    },
    //接口请求begin
    //获取区级站点数据
    selectDistrictStationInfo(adcode, curAdcode) {
      console.log(adcode, curAdcode, "原地图区级站点数据")
      let _this = this;
      let params = {
        enCode: 'TiDB-zhandian-zhandianjingweidu', //TiDB-全国地图
        '@province': curAdcode,
      };
      dataInterface
        .dataFaceApi(params)
        .then((res) => {
          if (!res) return;
          this.stationList = res || [];
          console.log(res, '区级站点数据')
          this.getGeoJson(adcode, curAdcode, res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //接口请求end
  },
};
</script>
<style lang="scss">
.mapChoose {
  position: absolute;
  left: 20px;
  top: 55px;
  color: #eee;

  .title {
    padding: 5px;
    border-top: 1px solid rgba(147, 235, 248, 0.8);
    border-bottom: 1px solid rgba(147, 235, 248, 0.8);
    cursor: pointer;
  }

  .icon {
    font-family: 'simsun';
    font-size: 25px;
    margin: 0 11px;
  }

  .superMap {
    min-width: 2008px;
  }
}

.marker {
  // background-image: url('mapbox-icon.png');
  background-size: cover;
  width: 165px;
  height: 45px;
  cursor: pointer;
  z-index: 1000;
}

.pop-content {
  background: url('~@/assets/images/index/area_bg.png');
  width: 222px;
  height: 184px;
  background-size: 100% 100%;
  padding: 5px 15px;
  cursor: pointer;
  .pop-img {
    flex: 1;
    padding: 5px 0;
    img {
      width: 100%;
      border: 1px solid rgba(120, 250, 255, 0.43);
    }
  }
}
.diamand {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left:20%;
  position: relative;
  .diamand2 {
    width: 60px;
    height: 36px;
    position: absolute;
    bottom: -30px;
    // left: 36%;
  }
  .diamand1 {
    left: 36%;
    width: 46px;
    height: 52px;
    animation: bounce-down 1.5s linear infinite;
  }
}
@keyframes bounce-down {
  25% {
    transform: translateY(-10px);
  }
  50%,
  100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(10px);
  }
}
</style>
